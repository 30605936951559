import { getCenter } from "ol/extent";

import { EXPORT_PARAM } from "./constants";

const getShortenedUrl = (apiUrl, location, callback = () => {}) => {
  return fetch(`${apiUrl}/?url=${encodeURIComponent(location)}`)
    .then((res) => {
      if (!res.ok) {
        throw res;
      }
      return res.json();
    })
    .then((data) => {
      callback(data);
      return data.url;
    });
};

export const getReadUrl = (
  currHref,
  kmlAdminId,
  kmlReadId,
  previewPathname,
  extent,
) => {
  // We replace the kmlAdminId by the kmlReadId.
  const readUrl = new URL(currHref.replace(kmlAdminId, kmlReadId));

  // Remove export Bbox from the readUrl
  readUrl.searchParams.delete(EXPORT_PARAM);

  // Set new center if extent provided (e.g. for bbox in PDF layouts)
  if (extent) {
    const center = getCenter(extent);
    readUrl.searchParams.set("x", center[0]);
    readUrl.searchParams.set("y", center[1]);
  }

  // We use the /plans route.
  return readUrl.toString().replace("/?", `${previewPathname}?`);
};

export default getShortenedUrl;
