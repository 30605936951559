import { useEffect, useState } from "react";

const DEFAULT_SCROLLBAR_WIDTH = 10;

const useScrollbarWidth = (wrapperEl, listEl) => {
  const [width, setWidth] = useState(0);
  useEffect(() => {
    const updateWidth = () => {
      if (wrapperEl && listEl) {
        const isOverflowing = listEl.scrollHeight > listEl.clientHeight;
        let newWidth = 0;
        if (isOverflowing) {
          const isOverlayScrollbar =
            wrapperEl.clientWidth === listEl.clientWidth;
          // FF overlays the scrollbar on Linux and the clientWidth doesn't change, so we force it
          newWidth = isOverlayScrollbar
            ? DEFAULT_SCROLLBAR_WIDTH
            : wrapperEl.clientWidth - listEl.clientWidth;
        }
        setWidth(newWidth);
      }
    };
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, [wrapperEl, listEl]);
  return width;
};

export default useScrollbarWidth;
