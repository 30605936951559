import { IMAGE_EXPORT_URI, KML_EXPORT_URI } from "../utils/constants";

const layouts = [
  {
    properties: {
      formats: ["image/png", "image/jpeg"],
      name: {
        de: "Plan exportieren",
        en: "Export plan",
        fr: "Exporter le plan",
        it: "Esporta piano",
      },
      northArrow: true,
      qrCode: true,
      scales: [1, 2, 3],
    },
    uri: IMAGE_EXPORT_URI,
  },
  {
    properties: {
      formats: ["application/vnd.google-earth.kml+xml"],
      lockBboxToMap: false,
      name: {
        de: "KML-Datei exportieren",
        en: "KML export",
        fr: "Exporter le fichier KML",
        it: "Esportazione del file KML",
      },
      selectBbox: false,
    },
    uri: KML_EXPORT_URI,
  },
];
export default layouts;
