import colors from "./colors";
import iconCategories from "./icons.json";

const config = {
  domains: ["ch"],
  font: "16px arial",
  iconCategories,
  iconGeneratorActive: true,
  id: "SOB",
  name: "SOB",
  slug: "sob",
  ssoTags: ["tp.ch.sob"],
  ...colors,
  pictures: [{ id: "sob_logo.png" }, { id: "sob_logo_black.png" }],
};
export default config;
