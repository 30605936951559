import KML from "react-spatial/utils/KML";

import cloneLayer from "./cloneLayer";
import { getDegreesFromRadians } from "./styleUtils";

const writeKml = (layer, map, rotation) => {
  const clone = cloneLayer(layer, map);

  let kmlString = KML.writeFeatures(
    clone,
    map.getView().getProjection(),
    map.getView().getResolution(),
  );

  if (kmlString && rotation) {
    kmlString = KML.writeDocumentCamera(kmlString, {
      heading: getDegreesFromRadians(rotation),
    });
  }

  return kmlString;
};

export default writeKml;
