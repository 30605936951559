// Always make sure red color is the first in the array (is selected for defaullt color for lines and polygons)
const defaultColors = [
  { border: "white", fill: [235, 0, 0, 1], hex: "#eb0000", name: "red" },
  { border: "white", fill: [45, 50, 125, 1], hex: "#2d327d", name: "blue" },
  {
    border: "black",
    fill: [255, 138, 61, 1],
    hex: "#ff8a3d",
    name: "orange",
  },
  { border: "black", fill: [0, 135, 90, 1], hex: "#00875a", name: "green" },
  { border: "white", fill: [0, 0, 0, 1], hex: "#000000", name: "black" },
  { border: "black", fill: [255, 192, 203, 1], hex: "#FFC0CB", name: "pink" },
  {
    border: "black",
    fill: [255, 255, 255, 1],
    hex: "#ffffff",
    name: "white",
  },
];

export default {
  colors: defaultColors,
  iconGeneratorColors: defaultColors,
  lineColors: defaultColors,
  polygonFillColors: defaultColors,
  polygonLineColors: defaultColors,
  textBgColors: [
    { fill: [255, 255, 255, 0.01], name: "none" },
    ...defaultColors,
  ],
};
