import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationDeCH from "./locales/de-ch.json";
import translationDeDE from "./locales/de-de.json";
import translationEN from "./locales/en.json";
import translationFR from "./locales/fr.json";
import translationIT from "./locales/it.json";

const resources = {
  "de-CH": {
    translation: translationDeCH,
  },
  "de-DE": {
    translation: translationDeDE,
  },
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  it: {
    translation: translationIT,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(
    {
      detection: {
        lookupLocalStorage: "i18nextLng",
        order: ["localStorage", "navigator"],
      },
      fallbackLng: "de-CH",
      interpolation: {
        escapeValue: false,
      },
      keySeparator: false,
      language: "de-CH",
      missingKeyHandler(lng, ns, key, fallbackValue) {
        // eslint-disable-next-line no-console
        console.log(`Missing translations: ${key}`);
        return fallbackValue;
      },
      react: {
        wait: false,
      },
      resources,
      // keySeparator: ' µ', // Deactivate the keySeparator option
      saveMissing: false,
    },
    (err) => {
      if (err) {
        // eslint-disable-next-line no-console
        console.error("i18n initialisation failed:", err);
      }
    },
  );

export default i18n;
