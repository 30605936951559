const textColors = [
  { border: "white", fill: [225, 14, 35, 1], hex: "#e10e23", name: "red" },
  { border: "white", fill: [158, 158, 158, 1], hex: "#9e9e9e", name: "gray1" },
  {
    border: "white",
    fill: [199, 199, 199, 1],
    hex: "#c7c7c7",
    name: "gray2",
  },
  { border: "white", fill: [0, 0, 0, 1], hex: "#000000", name: "black" },
  { border: "black", fill: [255, 255, 255, 1], hex: "#4EA770", name: "white" },
  {
    border: "black",
    fill: [45, 50, 125, 1],
    hex: "#2d327d",
    name: "blue",
  },
];

// Always make sure red color is the first in the array (is selected for defaullt color for lines and polygons)
const defaultColors = [
  { border: "white", fill: [225, 14, 35, 1], hex: "#e10e23", name: "red" },
  { border: "white", fill: [149, 116, 88, 1], hex: "#957458", name: "copper" },
  {
    border: "black",
    fill: [210, 218, 77, 1],
    hex: "#D2DA4D",
    name: "green",
  },
  { border: "black", fill: [0, 154, 203, 1], hex: "#009ACB", name: "azure" },
  { border: "white", fill: [0, 0, 0, 1], hex: "#000000", name: "black" },
  {
    border: "black",
    fill: [45, 50, 125, 1],
    hex: "#2d327d",
    name: "blue",
  },
];

export default {
  colors: textColors,
  iconGeneratorColors: defaultColors,
  lineColors: defaultColors,
  polygonFillColors: defaultColors,
  polygonLineColors: defaultColors,
  textBgColors: [{ fill: [255, 255, 255, 0.01], name: "none" }, ...textColors],
};
