/* OpenLayers */
export const OL_ICON_CACHE_SIZE = 2000;

/* Route */
export const PREVIEW_PATHNAME = "/plans";
export const DEFAULTS_PATHNAME = "/defaults";

/* Jasper params */
export const MAPSET_LAYOUT_TITLE = "MAPSET_LAYOUT_TITLE";
export const MAPSET_LAYOUT_MAP = "MAPSET_LAYOUT_MAP";
export const MAPSET_LAYOUT_DESCRIPTION = "MAPSET_LAYOUT_DESCRIPTION";

/* Mapset rights */
export const RIGHT_SET_DEFAULTS =
  /^mapset\.tp\.[A-Za-z0-9]*\.[A-Za-z0-9]*\.defaultplan$/; // Individual providers
export const RIGHT_SET_DEFAULTS_SU = /^mapset\.defaultplans\.setdefaults$/; // Superuser (internal)
export const RIGHT_MODUL_1 = /^geops\.marketing\.mapset_modul1$/; // Superuser (internal)

/* Layer names */
export const INSPECT_LAYER_NAME = "inspectLayer";
export const INSPECT_OL_LAYER_NAME = "inspect-ol-layer";
export const EDIT_LAYER_NAME = "editLayer";
export const EDIT_OL_LAYER_NAME = "edit-ol-layer";
export const BASE_LAYER_NAME = "basemap";
export const AERIAL_LAYER_NAME = "aerial";
export const SATURATED_LAYER_NAME = "saturated";

/* Dialog names */
export const LAYERS_DIALOG_NAME = "layers";
export const WARN_DIALOG_NAME = "warn";
export const PLAN_NAME_DIALOG_NAME = "planName";
export const SIGNOUT_DIALOG_NAME = "signout";
export const NEW_PLAN_DIALOG_NAME = "new";
export const SETTINGS_DIALOG_NAME = "settings";
export const ERROR_DIALOG_NAME = "error";
export const GROUP_DIALOG_NAME = "group";
export const SHARE_DIALOG_NAME = "share";
export const SAVE_DIALOG_NAME = "save";
export const EXPORT_DIALOG_NAME = "export";
export const SBBWELCOME_DIALOG_NAME = "welcome";
export const FEATURE_STYLER_DIALOG_NAME = "feature-styler";
export const ROUTE_FINDER_DIALOG_NAME = "route-finder";
export const PICTURE_STYLER_DIALOG_NAME = "picture-styler";
export const PLANS_LIST_DIALOG_NAME = "plans-list";
export const PLAN_DELETE_DIALOG_NAME = "plan-delete";
export const PLAN_CHANGE_DIALOG_NAME = "plan-change";
export const DID_YOU_KNOW_DIALOG_NAME = "did-you-know";
export const SAVE_CONFIRM_DIALOG_NAME = "save-confirm";
export const FEEDBACK_DIALOG_NAME = "feedback";
export const LAYOUT_PROTECTED_DIALOG_NAME = "layout-protected";
export const CUSTOM_LAYOUT_DIALOG_NAME = "custom-layout";
export const RELOAD_DIALOG_NAME = "reload";
export const ALL_DIALOGS_NAMES = [
  LAYERS_DIALOG_NAME,
  WARN_DIALOG_NAME,
  PLAN_NAME_DIALOG_NAME,
  SIGNOUT_DIALOG_NAME,
  NEW_PLAN_DIALOG_NAME,
  SETTINGS_DIALOG_NAME,
  ERROR_DIALOG_NAME,
  GROUP_DIALOG_NAME,
  SHARE_DIALOG_NAME,
  SAVE_DIALOG_NAME,
  EXPORT_DIALOG_NAME,
  SBBWELCOME_DIALOG_NAME,
  FEATURE_STYLER_DIALOG_NAME,
  ROUTE_FINDER_DIALOG_NAME,
  PICTURE_STYLER_DIALOG_NAME,
  PLANS_LIST_DIALOG_NAME,
  PLAN_DELETE_DIALOG_NAME,
  PLAN_CHANGE_DIALOG_NAME,
  DID_YOU_KNOW_DIALOG_NAME,
  SAVE_CONFIRM_DIALOG_NAME,
  FEEDBACK_DIALOG_NAME,
  LAYOUT_PROTECTED_DIALOG_NAME,
  CUSTOM_LAYOUT_DIALOG_NAME,
  RELOAD_DIALOG_NAME,
];

/* Map class name */
export const MAP_CLASS_NAME = "pe-map";

/* Header */
export const HEADER_HEIGHT = 70;

/* Sidebar */
export const SIDEBAR_WIDTH = 50;
export const SIDEBAR_WIDTH_OPEN = 270;

/* Active Button names */
export const TEXT_BUTTON_NAME = "Text zeichnen";
export const SYMBOL_BUTTON_NAME = "Piktogramm zeichnen";
export const LINE_BUTTON_NAME = "Linie zeichnen";
export const POLYGON_BUTTON_NAME = "Fläche zeichnen";
export const TRIANGLE_BUTTON_NAME = "Dreieck zeichnen";
export const PICTURE_BUTTON_NAME = "Bild einfügen";
export const STOP_FINDER_NAME = "StopFinder";

/* Local storage keys */
export const LS_BBOX_KEY = "pe.bbox.info";
export const LS_KML_KEY = "pe.kml";
export const LS_LANGUAGE_KEY = "i18nextLng";
export const LS_SIZE_KEY = "pe.max.canvas.size";
export const LS_ICON_CAT_COLLASPE = "pe.icon.categories.collapse";
export const LS_EXTENT_KEY = "pe.extent";
export const LS_CD_KEY = "pe.cd";
export const LS_HIDE_SBB_WELCOME = "pe.sbb.welcome";
export const LS_DID_YOU_KNOW_LIST = "pe.did.you.know.list";
export const LS_HIDE_DID_YOU_KNOW = "pe.did.you.know.hide";
export const LS_COUNT_ANON_USE = "pe.count.anon.use";
export const LS_SNAPPING_TYPE = "pe.snapping.type";
export const LS_EXPORT_QR_CODE = "pe.export.qr.code";
export const LS_EXPORT_FORMAT = "pe.export.format";
export const LS_EXPORT_TYPE = "pe.export.type";

/* Environments */
export const CH_ENVIRONMENT = "ch";
export const IO_ENVIRONMENT = "io";

/* Anonymous use */
export const MAX_ANON_USE = 3;

/* Editor */
export const HIT_TOLERANCE = 5;
export const SNAP_CONTROL_POINTS = "points";
export const SNAP_CONTROL_LINES = "lines";
export const SNAP_CONTROL_OFF = "off";

/* Styles */
export const SELECT_STYLE_Z_INDEX = 1000;
export const PICTURES_Z_INDEX = 100;
export const POLYGONS_Z_INDEX = 200;
export const LINES_Z_INDEX = 250;
export const ICONS_LABELS_Z_INDEX = 300;
export const ICON_SIZES = [
  {
    id: "klein",
    scale: (1 / 144) * 26, // 26px, official max size in der style
    value: [26, 26],
  },
  {
    id: "mittel",
    scale: 1 / 4, // 144px / 4 = 36px
    value: [36, 36],
  },
  {
    id: "gross",
    scale: 1 / 3, // 144px / 3 = 144px
    value: [48, 48],
  },
];
export const POLYGON_LINE_WIDTHS = [
  { id: "very-thin-line", value: 2 },
  { id: "thin-line", value: 4 },
  { id: "medium-line", value: 6 },
  { id: "thick-line", value: 8 },
];

/* Feature property keys */
export const OLD_STYLES_FUNCTION_PROP = "oldStyleFunction";
export const TEXT_BACKGROUND_OPACITY = "textBackgroundOpacity";
export const TEXT_STROKE_OPACITY = "textStrokeOpacity";
export const POLYGON_STROKE_OPACITY = "polygonStrokeOpacity";
export const POLYGON_FILL_OPACITY = "polygonFillOpacity";
export const LINE_STROKE_OPACITY = "lineStrokeOpacity";

/* Pictures */
export const DEFAULT_PIC_SCALE = 1 / 6;

/* Mapbox */
export const STYLE_REVIEW_PREFIX =
  process.env.REACT_APP_STYLE_REVIEW_PREFIX || "";
export const MAPSET_BUS_DATA_SOURCE = "busses"; // we display popup only using if the bus layer use this data source
export const MAPSET_METADATA_FILTER = "mapset.filter";
export const MOT_METADATA_FILTER = "general.mot";
export const MAPSET_METADATA_BUS_VALUE = "mapset_highlight_bus";
export const MAPSET_METADATA_TRAM_VALUE = "mapset_highlight_tram";
export const MOT_METADATA_BUS_VALUE = "bus";
export const MOT_METADATA_TRAM_VALUE = "tram";
export const MOT_METADATA_FERRY_VALUE = "ferry";
export const MOT_METADATA_SUBWAY_VALUE = "subway";
export const MOT_METADATA_RAIL_VALUE = "rail";
export const MOT_METADATA_CABLEWAY_VALUE = "cableway";
export const BASE_BRIGHT_NETZKARTE_MAPSET_STYLE =
  "base_bright_v2_ch.sbb.netzkarte_mapset";
export const BASE_BRIGHT_NETZKARTE_SATURIERT_MAPSET_STYLE =
  "base_bright_v2_saturiert_ch.sbb.netzkarte_mapset";
export const TRAVIC_MAPSET_STYLE = "travic_v2_mapset";

export const MAPBOX_STYLELAYERS = [
  // When updating the stylelayers, also copy the array into the /scripts/mapbox-stylelayers.test.mjs
  {
    name: "haltestellen",
    properties: {
      domains: ["io", "ch"],
      filterRegex: /mapset_stations/,
    },
    visible: true,
  },
  {
    name: "haltestellenschilder",
    properties: {
      domains: ["io", "ch"],
      filterRegex: /mapset_station_panels/,
    },
    visible: false,
  },
  {
    name: "haltekanten",
    properties: {
      domains: ["io", "ch"],
      filterRegex: /(mapset_stop_position|mapset_station_platform)/,
    },
    visible: true,
  },
  {
    name: "fusswege",
    properties: {
      domains: ["io", "ch"],
      filterAttribute: MAPSET_METADATA_FILTER,
      filterRegex: /path/,
    },
    visible: true,
  },
  {
    name: "pois",
    properties: {
      domains: ["io", "ch"],
      filterRegex: /mapset_poi/,
    },
    visible: true,
  },
  {
    children: [
      {
        name: "bahnlinien",
        properties: {
          domains: ["io", "ch"],
          filterAttribute: MOT_METADATA_FILTER,
          filterRegex: new RegExp(MOT_METADATA_RAIL_VALUE),
        },
        visible: true,
      },
      {
        name: "buslinien",
        properties: {
          domains: ["io", "ch"],
          filterAttribute: MOT_METADATA_FILTER,
          filterRegex: new RegExp(MOT_METADATA_BUS_VALUE),
          isBusLines: true,
          minZoom: 15,
        },
        visible: false,
      },
      {
        name: "tramlinien",
        properties: {
          domains: ["io", "ch"],
          filterAttribute: MOT_METADATA_FILTER,
          filterRegex: new RegExp(MOT_METADATA_TRAM_VALUE),
        },
        visible: true,
      },
      {
        name: "ubahnlinien",
        properties: {
          domains: ["io", "ch"],
          filterAttribute: MOT_METADATA_FILTER,
          filterRegex: new RegExp(MOT_METADATA_SUBWAY_VALUE),
        },
        visible: true,
      },
      {
        name: "schiffslinien",
        properties: {
          domains: ["io", "ch"],
          filterAttribute: MOT_METADATA_FILTER,
          filterRegex: new RegExp(MOT_METADATA_FERRY_VALUE),
        },
        visible: true,
      },
      {
        name: "seilbahnen",
        properties: {
          domains: ["io", "ch"],
          filterAttribute: MOT_METADATA_FILTER,
          filterRegex: new RegExp(MOT_METADATA_CABLEWAY_VALUE),
        },
        visible: true,
      },
    ],
    name: "verkehrsmittel",
  },
  {
    name: "bahnofplan",
    properties: {
      domains: ["ch", "io"],
      filterRegex: /mapset_station_plan/,
    },
    visible: true,
  },
  {
    name: "unterfuehrungen",
    properties: {
      domains: ["ch", "io"],
      filterRegex: /mapset_underground/,
    },
    visible: true,
  },
  {
    name: "pärke",
    properties: {
      domains: ["io", "ch"],
      filterRegex: /mapset_greenareas/,
    },
    visible: true,
  },
  {
    name: "strassen",
    properties: {
      domains: ["io", "ch"],
      filterRegex: /mapset_street_names/,
    },
    visible: true,
  },
  {
    name: "gewässerlabel",
    properties: {
      domains: ["io", "ch"],
      filterRegex: /mapset_water/,
    },
    visible: true,
  },
  {
    name: "ortslabel",
    properties: {
      domains: ["io", "ch"],
      filterRegex: /mapset_place/,
    },
    visible: true,
  },
  {
    name: "tunnelbeschriftungen",
    properties: {
      domains: ["io", "ch"],
      filterRegex: /mapset_tunnel_labels/,
    },
    visible: true,
  },
];

/* Permalink */
export const ROTATION_PARAM = "rotation";
export const DRAW_PARAM = "draw.id";
export const DRAW_OLD_PARAM = "wkp.draw";
export const EXPORT_PARAM = "export.bbox";
export const EXPORT_SCALE_PARAM = "export.resolution";
export const EXPORT_MAPLOCKED_PARAM = "export.maplocked";
export const LAYERS_PARAM = "layers";
export const HIDDEN_LAYERS_PARAM = "hidden.layers";
export const BUS_FILTERS_PROVIDER_PARAM = "busfilters.provider";
export const BUS_FILTERS_LINE_PARAM = "busfilters.line";
export const LANG_PARAM = "lang";
export const PARENT_PARAM = "parent";
export const BASE_LAYER_PARAM = "baselayer";
export const VIEW_EXTENT_PARAM = "view.extent"; // @deprecated use max.extent param

/* Save status */
export const STATE_SAVING = "saving";
export const STATE_SAVED = "saved";
export const STATE_SAVE = "save";

/* Zoom limits */
export const MINZOOM_DEFAULT = 0;
export const MAXZOOM_DEFAULT = 20;
export const RANGEZOOM_DEFAULT = [MINZOOM_DEFAULT, MAXZOOM_DEFAULT];

/* Routing */
export const OTHER_MOTS = [
  "tram",
  "coach",
  "subway",
  "gondola",
  "funicular",
  "ferry",
  "car",
];

/* Layout export */
export const LOAD_SIGNAL_ID = "load-signal";
export const LAYOUT_MIN_EXPORT_WIDTH = 500; // This is the minimum width for the north arrow, scalebar and copyright to scale properly

/* Image export */
export const IMAGE_EXPORT_URI = "mapset/image_export";
export const KML_EXPORT_URI = "mapset/kml_export";
export const EXPORT_URI_ALLOW_ANONYMOUS = [IMAGE_EXPORT_URI, KML_EXPORT_URI];
export const DEFAULT_EXPORT_SIZE = [300, 300];
export const EXTRA_DATA_SIZE = 80;
export const EXTRA_DATA_SCALE_THRESHOLD = 500;
export const EXTRA_DATA_LOGO_MIN_WIDTH = 43.4;
export const EXTRA_DATA_LOGO_MIN_HEIGHT = 15;
export const EXTRA_DATA_NORTH_ARROW_MIN_SIZE = 40;
export const EXTRA_DATA_COPYRIGHT_MIN_FONTSIZE = 9.1;
export const EXTRA_DATA_COPYRIGHT_DEFAULT_FONTSIZE = 10;
export const EXTRA_DATA_QRCODE_WIDTHS = [
  { id: "kein QR-Code", value: 0 },
  { id: "klein", value: EXTRA_DATA_SIZE },
  { id: "mittel", value: EXTRA_DATA_SIZE * 2 },
  { id: "gross", value: EXTRA_DATA_SIZE * 3 },
];

/* Date format */
export const INPUT_DATE_FORMAT = "yyyy-MM-dd";
export const INPUT_TIME_FORMAT = "HH:mm";
export const INPUT_DATE_PATTERN = "d{4}-d{2}-d{2}"; // Used by type="text" fallback
export const INPUT_TIME_PATTERN = "d{2}:d{2}"; // Used by type="text" fallback

/* Plans list */
export const PLANS_LIST_MAX_DISPLAY_COUNT = 50;

/*  MIME types */
export const MIME_TYPE_PNG = "image/png";
export const MIME_TYPE_JPEG = "image/jpeg";
export const MIME_TYPE_PDF = "application/pdf";
export const MIME_TYPE_KML = "application/vnd.google-earth.kml+xml";

/*  Draw types */
export const DRAW_TYPE_PROPERTY = "mapsetDrawType";
export const DRAW_TYPE_ICON = "Icon";
export const DRAW_TYPE_LINE = "Line";
export const DRAW_TYPE_POLYGON = "Polygon";
export const DRAW_TYPE_LABEL = "Label";
export const DRAW_TYPE_PICTURE = "Picture";
export const DRAW_TYPE_CIRCLE = "Circle";
export const DRAW_TYPE_BOX = "Box";
export const DRAW_TYPE_TRIANGLE = "Triangle";
export const DRAW_TYPE_ROUTE = "Route";

export const DRAW_TYPE_GROUP_PROPERTY = "mapsetDrawTypeGroup";
export const DRAW_TYPE_GROUP_AREA = "area";
export const DRAW_TYPE_GROUP_LINE = "line";
export const DRAW_TYPE_GROUP_POINT = "point";

export const DRAW_TYPES_BY_GROUP = {
  [DRAW_TYPE_GROUP_AREA]: [
    DRAW_TYPE_POLYGON,
    DRAW_TYPE_BOX,
    DRAW_TYPE_TRIANGLE,
    DRAW_TYPE_CIRCLE,
  ],
  [DRAW_TYPE_GROUP_LINE]: [DRAW_TYPE_LINE, DRAW_TYPE_ROUTE],
  [DRAW_TYPE_GROUP_POINT]: [DRAW_TYPE_ICON, DRAW_TYPE_PICTURE],
};

export const DRAW_TYPES = [
  DRAW_TYPE_ICON,
  DRAW_TYPE_LINE,
  DRAW_TYPE_POLYGON,
  DRAW_TYPE_LABEL,
  DRAW_TYPE_PICTURE,
  DRAW_TYPE_CIRCLE,
  DRAW_TYPE_BOX,
  DRAW_TYPE_TRIANGLE,
  DRAW_TYPE_ROUTE,
];

// Export types
export const exportTypes = ["pdf", "image", "kml"];

// Broadcast messages
export const BROADCAST_MSG_LOGIN = "mapset-login";
export const BROADCAST_MSG_LOGOUT = "mapset-logout";
