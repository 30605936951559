import {
  AERIAL_LAYER_NAME,
  BASE_BRIGHT_NETZKARTE_MAPSET_STYLE,
  BASE_BRIGHT_NETZKARTE_SATURIERT_MAPSET_STYLE,
  BASE_LAYER_NAME,
  SATURATED_LAYER_NAME,
  STYLE_REVIEW_PREFIX,
} from "../../utils/constants";
import colors from "./colors";
import iconCategories from "./icons.json";

const config = {
  domains: ["ch"],
  font: "16px arial",
  iconCategories,
  iconGeneratorActive: true,
  id: "ch",
  isDefault: true,
  name: "CH",
  settings: {
    stops_api_bbox: "5.657959,45.675482,10.722656,47.908978",
    stops_api_prefagencies: "sbb",
  },
  slug: "mapsetch",
  ssoTags: ["default"],
  ...colors,
  baseLayers: [
    {
      key: "ch.sbb.netzkarte",
      name: BASE_LAYER_NAME,
      style: `${STYLE_REVIEW_PREFIX}${BASE_BRIGHT_NETZKARTE_MAPSET_STYLE}`,
    },
    {
      key: "ch.sbb.netzkarte_saturiert",
      name: SATURATED_LAYER_NAME,
      style: `${STYLE_REVIEW_PREFIX}${BASE_BRIGHT_NETZKARTE_SATURIERT_MAPSET_STYLE}`,
    },
    {
      key: "world.geops.aerial_sbb",
      name: AERIAL_LAYER_NAME,
      style: `${STYLE_REVIEW_PREFIX}aerial_sbb_ch.sbb.netzkarte.aerial`,
    },
  ],
};
export default config;
