import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import configs from "../../config";
import { setConfigs, setCorporateDesign } from "../../model/app/actions";
import { setUser } from "../../model/oidc/actions";
import { BROADCAST_MSG_LOGIN } from "../constants";
import fetchUserInfo from "../fetchUserInfo";
import mergeConfigs from "../mergeConfigs";

export default function useAuthenticate() {
  const dispatch = useDispatch();
  const domain = useSelector((state) => state.app.domain);
  const ssoConfigsUrl = useSelector((state) => state.app.ssoConfigsUrl);
  const userInfoUrl = useSelector((state) => state.app.userInfoUrl);
  const broadcast = useSelector((state) => state.oidc.broadcast);
  const [userInfo, setUserInfo] = useState(undefined);
  const [isAuthComplete, setIsAuthComplete] = useState(false);
  const [isUserInfoComplete, setIsUserInfoComplete] = useState(false);

  useEffect(() => {
    const abortCtrlLogin = new AbortController();
    const getUserInfo = async () => {
      const newUserInfo = await fetchUserInfo(userInfoUrl, {
        signal: abortCtrlLogin.signal,
      });
      if (!newUserInfo) {
        setIsUserInfoComplete(true);
        setUserInfo(null);
        return;
      }
      setIsUserInfoComplete(true);
      setUserInfo(newUserInfo);
    };
    getUserInfo();

    return () => {
      abortCtrlLogin.abort();
    };
  }, [domain, ssoConfigsUrl, userInfoUrl]);

  useEffect(() => {
    if (userInfo && broadcast) {
      broadcast.postMessage(BROADCAST_MSG_LOGIN);
    }
  }, [broadcast, userInfo]);

  useEffect(() => {
    const abortCtrlConfig = new AbortController();
    const updateConfig = async () => {
      const clientConfigs = Object.values(configs).filter((config) =>
        config.domains.includes(domain),
      );
      let serverConfigs = userInfo?.configs;
      if (!serverConfigs?.length) {
        // Fetch the default configs if no user or the user has no configs
        try {
          const response = await fetch(`${ssoConfigsUrl}/mapset${domain}`, {
            signal: abortCtrlConfig.signal,
          });
          const data = await response.json();
          serverConfigs = [data];
        } catch (err) {
          if (err && err.name === "AbortError") {
            // ignore user abort request
            return;
          }
        }
      }

      const finalConfigs = mergeConfigs(clientConfigs, serverConfigs);

      dispatch(setUser(userInfo));
      dispatch(setConfigs(finalConfigs));
      dispatch(setCorporateDesign());
      setIsAuthComplete(true);
    };

    if (isUserInfoComplete) {
      updateConfig();
    }

    return () => {
      abortCtrlConfig.abort();
    };
  }, [dispatch, domain, isUserInfoComplete, ssoConfigsUrl, userInfo]);

  return { isAuthComplete, userInfo };
}
