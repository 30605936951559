import { SET_BROADCAST, SET_USER } from "./actions";

export default function oidc(
  state = { authComplete: false, user: null },
  action,
) {
  switch (action.type) {
    case SET_USER:
      if (!action?.data?.sso_userinfo) return state;
      return {
        ...state,
        user: {
          profile: action.data.sso_userinfo,
        },
      };
    case SET_BROADCAST:
      return {
        ...state,
        broadcast: action.data,
      };
    default:
      return {
        ...state,
      };
  }
}
