import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "./utils/polyfills/append"; // IE11: error triggered when we export the canvas.
import * as Sentry from "@sentry/react";
import "abortcontroller-polyfill/dist/abortcontroller-polyfill-only";
import React from "react";
import { createRoot } from "react-dom/client";

import AppRouter from "./AppRouter";
import environment from "./envConfig";
import "./i18n";
import registerServiceWorker from "./serviceWorker";

Sentry.init(environment.sentry);

if (environment.app.pwaActive) {
  registerServiceWorker();
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<AppRouter />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
