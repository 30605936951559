import { Circle, LineString, Polygon } from "ol/geom";

import {
  ICONS_LABELS_Z_INDEX,
  LINES_Z_INDEX,
  PICTURES_Z_INDEX,
  POLYGONS_Z_INDEX,
} from "./constants";

const getStyleZIndex = (feature, isPicture = false) => {
  /* We always set zIndices when editing features for backwards compatibility */
  const geom = feature.getGeometry();
  if (isPicture) {
    return PICTURES_Z_INDEX;
  }

  if (geom instanceof LineString) {
    return LINES_Z_INDEX;
  }

  if (geom instanceof Polygon || geom instanceof Circle) {
    return POLYGONS_Z_INDEX;
  }

  return ICONS_LABELS_Z_INDEX;
};

export default getStyleZIndex;
