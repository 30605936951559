import colors from "./colors";
import iconCategories from "./icons.json";

const config = {
  domains: ["ch"],
  font: "16px arial",
  iconCategories,
  iconGeneratorActive: true,
  id: "forchbahn",
  name: "Forchbahn",
  slug: "forchbahn",
  ssoTags: ["tp.ch.fb"],
  ...colors,
};
export default config;
