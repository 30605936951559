import { unByKey } from "ol/Observable";
import { Icon } from "ol/style";
import Control from "ole/src/control/control";

import { DEFAULT_PIC_SCALE } from "../../utils/constants";
import createStyles from "../../utils/createStyles";
import getSizeFromImageStyle from "../../utils/getSizeFromImageStyle";
import getSizeFromTextStyle from "../../utils/getSizeFromTextStyle";
import {
  getDegreesFromRadians,
  getFeatureStyle,
  getRadiansFromDegrees,
} from "../../utils/styleUtils";
import Transform from "./Transform";

const TMP_INITIAL_ROTATION_PROP = "tmpInitialRotation";

/**
 * Tool with for scaling/moving/rotating geometries.
 */
class TransformControl extends Control {
  /**
   * @inheritdoc
   * @param {Object} [options] Control options.
   * @param {string} [options.rotateAttribute] Name of a feature attribute
   *   that is used for storing the rotation in rad.
   * @param {ol.style.Style.StyleLike} [options.style] Style used for the rotation layer.
   */
  constructor(options = {}) {
    super({
      element: null,
      ...options,
    });

    this.standalone = false;
    this.olKeys = [];
    this.transformInteraction = undefined;
    this.snapControl = options.snapControl;
    this.bufferInPixels = 30;
  }

  activate(features) {
    super.activate();
    if (this.transformInteractions?.length) {
      this.deactivate();
    }

    // Depending on the type of objects drawn we want probably different behavior.
    // So we creates the interactions here.
    this.transformInteractions = this.createInteractions(features);

    if (!this.transformInteractions?.length) {
      return;
    }

    this.transformInteractions.forEach((interaction, index) => {
      this.map?.addInteraction(interaction);
      interaction.setActive(true);
      // It's important to execute this here otherwise the  change is overwritten
      // by the interaction.setMap function
      interaction.select(features[index], true);
    });
  }

  createInteractionForCircle(options) {
    const interaction = new Transform({
      buffer: this.getBuffer.bind(this),
      enableRotatedTransform: true,
      keepAspectRatio: () => true,
      translate: true, // Move the feature using an handle instead of the whole feature
      translateBBox: false, // Move the feature using an handle instead of the whole feature
      translateFeature: false, // Move the feature using an handle instead of the whole feature
      ...options,
    });

    this.updateBufferForLineAndPolygon(interaction);

    this.olKeys.push(
      this.map?.getView()?.on("change:resolution", (evt) => {
        this.updateBufferForLineAndPolygon(interaction, evt);
      }),
      this.map?.getView()?.on("change:rotation", (evt) => {
        this.updateBufferForLineAndPolygon(interaction, evt);
      }),
    );
    return interaction;
  }

  createInteractionForLineAndPolygon(options) {
    const interaction = new Transform({
      buffer: this.getBuffer.bind(this),
      enableRotatedTransform: true,
      keepAspectRatio: () => true,
      translateFeature: false, // Move the feature using an handle instead of the whole feature
      ...options,
    });

    this.updateBufferForLineAndPolygon(interaction);

    this.olKeys.push(
      this.map?.getView()?.on("change:resolution", (evt) => {
        this.updateBufferForLineAndPolygon(interaction, evt);
        // eslint-disable-next-line no-underscore-dangle
        interaction?.drawSketch_(interaction);
      }),

      this.map?.getView()?.on("change:rotation", (evt) => {
        this.updateBufferForLineAndPolygon(interaction, evt);
        // eslint-disable-next-line no-underscore-dangle
        interaction?.drawSketch_(interaction);
      }),
    );
    return interaction;
  }

  createInteractionForPoint(options) {
    const interaction = new Transform({
      enableRotatedTransform: true,
      keepRectangle: false,
      pointRadius: this.getPointRadius.bind(this),
      scale: false,
      translate: true,
      translateBBox: false,
      translateFeature: true,
      ...options,
    });

    this.olKeys.push(
      this.snapControl.snapInteraction.on("snap", (evt) => {
        // When we grab a feature with an image at its border then translate it, the snap
        // occurs on the mouse cursor not the geometry of the point.
        // So when the snap happens we move the geometry on the snapping vertex.
        const editFeature = this.editor.getEditFeature();
        if (editFeature) {
          editFeature.getGeometry()?.setCoordinates(evt.vertex);
        }
      }),

      interaction.on("translatestart", (evt) => {
        this.editor.setEditFeature(evt.feature);
        this.isModifying = true;
        this.snapControl?.activate();
      }),

      interaction.on("translateend", () => {
        this.editor.setEditFeature();
        this.isModifying = false;
        this.snapControl?.deactivate();
      }),

      // Those listeners are used only when we rotate on Point Geometry,
      // to be able to update the style of the point.
      interaction.on("rotatestart", this.onRotateStart.bind(this)),
      interaction.on("rotating", this.onRotating.bind(this)),
      interaction.on("rotateend", this.onRotateEnd.bind(this)),

      // The handles are geometries so we have to redraw each
      // time the resolution changes.
      this.map?.getView()?.on("change:resolution", () => {
        // eslint-disable-next-line no-underscore-dangle
        interaction?.drawSketch_();
      }),
      this.map?.getView()?.on("change:rotation", () => {
        // eslint-disable-next-line no-underscore-dangle
        interaction?.drawSketch_();
      }),
    );
    return interaction;
  }

  createInteractions(features) {
    const options = {
      hitTolerance: 5,
      rotate: features.length === 1,
      selection: false, // Let the ole editor handle the selection/deselection
    };

    return (features || []).map((feature) => {
      let interaction;
      const geometry = feature.getGeometry();
      if (geometry.getType() === "Point") {
        interaction = this.createInteractionForPoint(options);
      } else if (geometry.getType() === "Circle") {
        interaction = this.createInteractionForCircle(options);
      } else {
        interaction = this.createInteractionForLineAndPolygon(options);
      }
      return interaction;
    });
  }

  deactivate(silent) {
    super.deactivate(silent);
    if (!this.transformInteractions) {
      return;
    }
    unByKey(this.olKeys);
    this.olKeys = [];
    this.transformInteractions.forEach((interaction) => {
      interaction.select(null, false);
      interaction.setActive(false);
      this.map?.removeInteraction(interaction);
    });
    this.transformInteractions = undefined;
  }

  getBuffer(feature) {
    try {
      const style = getFeatureStyle(feature);
      const width = style?.getStroke()?.getWidth() || 0;
      return this.getBufferFromPixel(this.bufferInPixels + width / 2);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error while getting point radius", e);
      return this.getBufferFromPixel(this.bufferInPixels);
    }
  }

  getBufferFromPixel(pixel) {
    const resolution = this.map?.getView()?.getResolution();
    if (!resolution || !pixel) {
      return 0;
    }
    return pixel * resolution;
  }

  getPointRadius(feature) {
    try {
      const buff = 10; // this.bufferInPixels / 2;
      let size = [0, 0];
      const style = getFeatureStyle(feature);
      const imageStyle = style?.getImage();
      const textStyle = style?.getText();
      if (textStyle) {
        size = getSizeFromTextStyle(textStyle);
      } else if (imageStyle) {
        size = getSizeFromImageStyle(imageStyle);

        if (size[0] === 0 && size[1] === 0) {
          imageStyle.getImage()?.addEventListener("load", () => {
            this.transformInteractions.forEach((interaction) => {
              this.map?.once("postrender", () => {
                // eslint-disable-next-line no-underscore-dangle
                interaction?.drawSketch_();
              });
            });
          });
        }

        // Manage Picture scale
        if (feature.get("pictureOptions")) {
          const mapResolution = this.map?.getView()?.getResolution();
          const scale = imageStyle?.getScale();
          const { defaultScale, resolution } = feature.get("pictureOptions");
          const iconScale =
            resolution && mapResolution
              ? (resolution / mapResolution) * defaultScale
              : DEFAULT_PIC_SCALE;
          size = size.map((s) => (s / scale) * iconScale);
        }
      }
      return size.map((s) => buff + s / 2);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error while getting point radius", e);
      return 0;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  onRotateEnd(evt) {
    evt.target?.unset(TMP_INITIAL_ROTATION_PROP);
  }

  // eslint-disable-next-line class-methods-use-this
  onRotateStart(evt) {
    const { feature, target } = evt;
    const geometry = feature.getGeometry();
    const type = geometry.getType();
    if (type === "Point") {
      const style = feature.getStyleFunction()?.(feature)?.[0];
      let styleWithRotation = style?.getText();

      // Labels have a Circle style as image, so we test if the image is an Icon
      const image = style?.getImage();
      if (image instanceof Icon) {
        styleWithRotation = image;
      }

      if (styleWithRotation) {
        target?.set(
          TMP_INITIAL_ROTATION_PROP,
          styleWithRotation.getRotation() || 0,
        );
      }
    }
  }

  onRotating(evt) {
    const { feature, target } = evt;
    const rotation = evt.angle;
    const geometry = feature.getGeometry();
    const type = geometry.getType();

    if (type === "Point") {
      // It's important to clone the style to make sure the FeatureStyler detects the change.
      const style = getFeatureStyle(feature)?.clone();
      let styleWithRotation = style?.getText();

      // Labels have a Circle style as image, so we test if the image is an Icon
      const image = style?.getImage();
      if (image instanceof Icon) {
        styleWithRotation = image;
      }

      if (styleWithRotation) {
        const initialRotation = target?.get(TMP_INITIAL_ROTATION_PROP);
        const rad = initialRotation - rotation;
        const deg = getDegreesFromRadians(rad);
        styleWithRotation?.setRotation(getRadiansFromDegrees(deg % 360));
        if (styleWithRotation instanceof Text) {
          style.setText(styleWithRotation);
        } else if (styleWithRotation instanceof Icon) {
          style.setImage(styleWithRotation);
        }

        feature.setStyle((feat, res) =>
          createStyles(feat, res, [style], this.map),
        );
      }
    }
  }

  updateBufferForLineAndPolygon(interaction, evt) {
    const { bufferInPixels } = this;
    const resolution =
      evt?.target?.getResolution() || this.map?.getView()?.getResolution();

    if (!resolution || !bufferInPixels) {
      interaction?.set("buffer", 0);
      return;
    }

    interaction?.set("buffer", this.getBufferFromPixel(bufferInPixels));
  }
}

export default TransformControl;
