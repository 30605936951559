import { Typography } from "@material-ui/core/index";
import { ThemeProvider } from "@material-ui/core/styles";
import "ol/ol.css";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import "./App.scss";
import "./Globals.scss";
import store from "./model/store";
import defaultTheme from "./themes";
import { useAuthenticate, useBroadcast } from "./utils/customHooks";

function AppChildren({ children, history, location, params }) {
  const { isAuthComplete } = useAuthenticate();
  useBroadcast();

  if (!isAuthComplete) {
    // Only load the app once the authentication process is complete
    return <Typography>Redirecting...</Typography>;
  }

  return React.Children.map(children, (child) => {
    return React.cloneElement(child, { history, location, params });
  });
}

AppChildren.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  params: PropTypes.shape({}).isRequired,
};

function App({ children }) {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const history = useMemo(() => {
    return {
      push: (path) => {
        navigate(path);
      },
      replace: (path) => {
        navigate(path);
      },
    };
  }, [navigate]);

  return (
    <Provider store={store}>
      <ThemeProvider theme={defaultTheme}>
        <AppChildren history={history} location={location} params={params}>
          {children}
        </AppChildren>
      </ThemeProvider>
    </Provider>
  );
}

App.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(App);
