import colors from "./colors";
import iconCategories from "./icons.json";

const config = {
  domains: ["ch"],
  font: "16px arial",
  iconCategories,
  iconGeneratorActive: true,
  id: "STIGWB",
  name: "STI & GWB",
  pictures: [
    { id: "gwb_niederflurbus_v1.png" },
    { id: "sti_niederflurbus_v1.png" },
    { id: "sti_gelenkbus_v1.png" },
  ],
  slug: "stigwb",
  ssoTags: ["tp.ch.gwb", "tp.ch.sti"],
  ...colors,
};
export default config;
