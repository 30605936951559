// Always make sure red color is the first in the array (is selected for defaullt color for lines and polygons)
const defaultColors = [
  { border: "white", fill: [226, 0, 26, 1], hex: "#e2001a", name: "red" },
  { border: "white", fill: [0, 0, 0, 1], hex: "#000000", name: "black" },
  {
    border: "black",
    fill: [214, 215, 215, 1],
    hex: "#d6d7d7",
    name: "grey",
  },
  { border: "black", fill: [137, 196, 231, 1], hex: "#89c4e7", name: "blue" },
  { border: "white", fill: [122, 86, 47, 1], hex: "#7a562f", name: "brown" },
  {
    border: "black",
    fill: [0, 176, 38, 1],
    hex: "#00b026",
    name: "green",
  },
];

export default {
  colors: defaultColors,
  iconGeneratorColors: defaultColors,
  lineColors: defaultColors,
  polygonFillColors: defaultColors,
  polygonLineColors: defaultColors,
  textBgColors: [
    { fill: [255, 255, 255, 0.01], name: "none" },
    ...defaultColors,
  ],
};
