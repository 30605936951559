import { createTheme, Fade } from "@material-ui/core";
import React from "react";

import CheckboxIcon from "../components/CheckboxIcon/CheckboxIcon";

const colors = {
  black: "#000",
  brandPrimary: "#1789c9",
  gray: "#767676",
  grayAltLight: "#e8e8e8",
  grayDark: "#414141",
  grayLight: "rgba(239, 239, 239, 1)", // #efefef
  red: "rgba(235, 0, 0, 0.7)",
  redLight: "rgba(235, 0, 0, 0.3)",
};

const theme = createTheme({
  colors,
  overrides: {
    MuiButton: {
      outlined: {
        borderColor: colors.grayLight,
        padding: "5px 10px",
      },
      root: {
        "& svg": {
          color: "inherit",
        },
        "&.Mui-focusVisible": {
          borderColor: colors.brandPrimary,
          borderWidth: 1,
        },
        minHeight: 40,
        minWidth: "unset",
        padding: "5px 10px",
        textTransform: "none",
      },
    },
    MuiButtonGroup: {
      grouped: {
        border: `1px solid ${colors.grayLight}`,
      },
      root: { height: 40 },
    },
    MuiCircularProgress: {
      colorSecondary: {
        color: colors.gray,
      },
      root: {
        "& svg": {
          margin: 0,
        },
      },
    },
    MuiFormControl: {
      root: {
        alignItems: "center",
        flexDirection: "row",
      },
    },
    MuiFormControlLabel: {
      root: {
        "&$disabled": {
          color: colors.grayDark,
          opacity: 0.38,
          pointerEvents: "none",
        },
      },
    },
    MuiInput: {
      inputMultiline: {
        resize: "vertical",
      },
    },
    MuiInputBase: {
      root: {
        "&$disabled": {
          color: colors.grayDark,
          opacity: 0.38,
        },
        "&$disabled:hover": {
          backgroundColor: "white",
          cursor: "not-allowed",
        },
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: "translate(0, 0) scale(1)",
      },
    },
    MuiListItem: {
      button: {
        "&:hover": {
          backgroundColor: colors.grayLight,
        },
      },
      root: {
        "&:hover": {
          backgroundColor: colors.grayLight,
        },
        backgroundColor: "white",
        border: `1px solid ${colors.grayLight}`,
        color: colors.grayDark,
        display: "flex",
        justifyContent: "space-between",
      },
    },
    MuiMenuItem: {
      root: {
        border: `1px solid ${colors.grayLight}`,
        minHeight: "48px !important",
        textAlign: "center",
        whiteSpace: "break-spaces",
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "12px 14px",
      },
      root: {
        "& $notchedOutline": {
          borderColor: colors.grayLight,
        },
        "&:hover": {
          backgroundColor: colors.grayLight,
        },
        "&:hover $notchedOutline": {
          borderColor: colors.grayLight,
          borderWidth: 1,
        },
        "&$disabled $notchedOutline": {
          borderColor: colors.grayLight,
          opacity: 0.38,
        },
        "&$focused $notchedOutline": {
          borderColor: colors.brandPrimary,
          borderWidth: 1,
        },
        borderRadius: 4,
        borderWidth: 1,
        boxSizing: "border-box",
      },
    },
    MuiSelect: {
      icon: {
        transition: "transform 400ms",
        width: 18,
      },
      root: {
        "&$disabled:hover": {
          cursor: "not-allowed",
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: "#fff",
        borderRadius: 0,
        color: colors.grayDark,
        minWidth: "0 !important",
      },
    },
    MuiTab: {
      root: {
        "&:hover": {
          backgroundColor: colors.grayLight,
        },
        "&$selected": {
          backgroundColor: "white",
          border: `1px solid ${colors.grayLight}`,
          borderBottom: "none",
          color: colors.brandPrimary,
          fontWeight: "bold",
        },
        "&$selected:hover": {
          backgroundColor: "white",
        },
        borderBottom: `1px solid ${colors.grayLight}`,
        minWidth: "70px!important",
        position: "relative",
        textTransform: "none",
      },
    },
    MuiTabs: {
      indicator: {
        display: "none",
      },
    },
    MuiToggleButton: {
      root: {
        "&.Mui-selected": {
          backgroundColor: colors.brandPrimary,
          color: "white",
        },
        "&.Mui-selected:hover": {
          backgroundColor: "#105f8c!important",
          color: "white",
        },
        color: colors.grayDark,

        fontWeight: "bold",
        textTransform: "none",
      },
    },
    MuiTypography: {
      body1: { whiteSpace: "pre-line" },
      h2: { margin: "12px 0" },
      h4: { margin: "8px 0" },
      paragraph: { margin: "8px 0" },
    },
    PrivateSwitchBase: {
      root: {
        padding: "6px 9px",
      },
    },
  },
  palette: {
    error: {
      light: colors.redLight,
      main: colors.red,
    },
    primary: {
      main: colors.brandPrimary,
    },
    secondary: {
      light: colors.grayLight,
      main: colors.grayDark,
      medium: colors.gray,
    },
    white: {
      main: "white",
    },
  },
  props: {
    MuiButton: {
      variant: "outlined",
    },
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiCheckbox: {
      checkedIcon: <CheckboxIcon checked />,
      icon: <CheckboxIcon />,
      size: "small",
      style: { backgroundColor: "transparent" },
    },
    MuiCircularProgress: {
      size: 20,
    },
    MuiDialog: {
      maxWidth: false,
    },
    MuiListItem: {
      component: "button",
    },
    MuiMenu: {
      TransitionComponent: Fade,
    },
    MuiToggleButtonGroup: {
      size: "small",
    },
  },
  styles: {
    link: {
      "&:hover": {
        color: colors.brandPrimary,
      },
      color: colors.grayDark,
    },
  },
  typography: {
    body1: {
      color: colors.grayDark,
      fontSize: "1em",
      fontWeight: "normal",
      lineHeight: "19.5px",
    },
    button: {
      fontSize: "1em",
      letterSpacing: 0,
      lineHeight: "26px",
    },
    fontFamily: "Lato, sans-serif",
    fontSize: 15,
    h2: {
      color: colors.grayDark,
      fontSize: "1.5em",
      fontWeight: "bold",
      lineHeight: "22.5px",
    },
    h4: {
      color: colors.grayDark,
      fontSize: "1em",
      fontWeight: "bold",
      lineHeight: "22.5px",
    },
    htmlFontSize: 15,
    subtitle1: {
      color: "#959494",
      fontSize: 13,
      lineHeight: "19.5px",
    },
  },
});

export default theme;
