/**
 * Return the document page title, using KML informations.
 * @param {*} data
 * @returns
 */
const getDocumentTitle = (data) => {
  let title = "mapset";
  if (data?.name) {
    title = `${data.name} | ${title}`;
  }
  return title;
};

export default getDocumentTitle;
