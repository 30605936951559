import colors from "./colors";
import iconCategories from "./icons.json";

const config = {
  domains: ["io"],
  font: "16px arial",
  iconCategories,
  iconGeneratorActive: true,
  id: "rheinbahn",
  name: "Rheinbahn",
  slug: "rheinbahn",
  ssoTags: ["tp.de.rheinbahn"],
  ...colors,
};
export default config;
