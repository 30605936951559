import { useMemo } from "react";
import { useSelector } from "react-redux";

import { INSPECT_LAYER_NAME } from "../constants";

const useInspectLayer = () => {
  const layerService = useSelector((state) => state.map.layerService);

  const layer = useMemo(() => {
    return layerService?.getLayer(INSPECT_LAYER_NAME);
  }, [layerService]);
  return layer;
};

export default useInspectLayer;
