/**
 * Return an approximative size of the canvas drawn by this text style.
 */
const getSizeFromImageStyle = (imageStyle) => {
  const width = imageStyle?.getWidth();
  const height = imageStyle?.getHeight();
  if (width === undefined && height === undefined) {
    // it happens when the image is loading
    return [0, 0];
  }
  const size = [width, height];
  return size;
};

export default getSizeFromImageStyle;
