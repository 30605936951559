function mergeConfigs(clientConfigs = [], serverConfigs = []) {
  const configMatches = clientConfigs.filter(
    (conf) =>
      serverConfigs.find((sConf) => sConf.slug === conf.slug) || conf.isDefault,
  );

  if (configMatches?.length === 1 && configMatches[0].isDefault) {
    // When there is a backend config but no local CD matches we merge the backend config settings into the default CD
    return [
      {
        ...configMatches[0],
        settings: serverConfigs.length
          ? {
              ...configMatches[0].settings,
              ...serverConfigs[0].settings,
            }
          : { ...configMatches[0] },
      },
    ];
  }
  const accessibleCds = clientConfigs.reduce((finalConfigs, conf) => {
    if (conf.isDefault) {
      return [conf, ...finalConfigs];
    }
    const accessibleConf = (serverConfigs || []).find(
      (bc) => bc.slug === conf.slug,
    );
    return accessibleConf
      ? [
          ...finalConfigs,
          {
            ...conf,
            ...accessibleConf,
            settings: {
              ...conf.settings, // Here we inject optional local settings
              ...accessibleConf.settings, // Settings from the backend
            },
          },
        ]
      : finalConfigs;
  }, []);
  return accessibleCds;
}

export default mergeConfigs;
