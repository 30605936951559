// Always make sure red color is the first in the array (is selected for defaullt color for lines and polygons)
const defaultColors = [
  {
    border: "black",
    fill: [210, 0, 25, 1],
    hex: "#d20019",
    name: "redTrenord",
  },
  {
    border: "black",
    fill: [0, 102, 51, 1],
    hex: "#006633",
    name: "greenTrenord",
  },
  {
    border: "white",
    fill: [0, 50, 77, 1],
    hex: "#00324D",
    name: "blueTrenord",
  },
  { border: "white", fill: [17, 17, 17, 1], hex: "#111111", name: "black" },
  {
    border: "black",
    fill: [153, 153, 153, 1],
    hex: "#999999",
    name: "gray",
  },
  { border: "black", fill: [255, 255, 255, 1], hex: "#ffffff", name: "white" },
];

export default {
  colors: defaultColors,
  iconGeneratorColors: defaultColors,
  lineColors: defaultColors,
  polygonFillColors: defaultColors,
  polygonLineColors: defaultColors,
  textBgColors: [
    { fill: [255, 255, 255, 0.01], name: "none" },
    ...defaultColors,
  ],
};
