/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable default-case */
/* eslint-disable no-loop-func */
/* eslint-disable consistent-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-cond-assign */
/* eslint-disable eqeqeq */
/* eslint-disable no-multi-assign */
/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-var */
/* eslint-disable no-underscore-dangle */
/* eslint-disable vars-on-top */
/* eslint-disable new-cap */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
import ol_Collection from "ol/Collection";
import {
  boundingExtent as ol_extent_boundingExtent,
  buffer as ol_extent_buffer,
  createEmpty as ol_extent_createEmpty,
  extend as ol_extent_extend,
  getCenter as ol_extent_getCenter,
} from "ol/extent";
import ol_Feature from "ol/Feature";
import ol_geom_Point from "ol/geom/Point";
import ol_geom_Polygon, {
  fromExtent as ol_geom_Polygon_fromExtent,
} from "ol/geom/Polygon";
import ol_interaction_Pointer from "ol/interaction/Pointer";
import ol_layer_Vector from "ol/layer/Vector";
import { unByKey as ol_Observable_unByKey } from "ol/Observable";
import ol_source_Vector from "ol/source/Vector";
import ol_style_Fill from "ol/style/Fill";
import ol_style_RegularShape from "ol/style/RegularShape";
import ol_style_Stroke from "ol/style/Stroke";
/* eslint-disable camelcase */
import ol_style_Style from "ol/style/Style";

import { getFeatureStyle } from "../../utils/styleUtils";

/** Interaction rotate
 * @constructor
 * @extends {ol_interaction_Pointer}
 * @fires select | rotatestart | rotating | rotateend | translatestart | translating | translateend | scalestart | scaling | scaleend
 * @param {any} options
 *  @param {function} options.filter A function that takes a Feature and a Layer and returns true if the feature may be transformed or false otherwise.
 *  @param {Array<ol.Layer>} options.layers array of layers to transform,
 *  @param {ol.Collection<ol.Feature>} options.features collection of feature to transform,
 *	@param {ol.EventsConditionType|undefined} options.condition A function that takes an ol.MapBrowserEvent and a feature collection and returns a boolean to indicate whether that event should be handled. default: ol.events.condition.always.
 *	@param {ol.EventsConditionType|undefined} options.addCondition A function that takes an ol.MapBrowserEvent and returns a boolean to indicate whether that event should be handled ie. the feature will be added to the transforms features. default: ol.events.condition.never.
 *	@param {number | undefined} options.hitTolerance Tolerance to select feature in pixel, default 0
 *	@param {bool} options.translateFeature Translate when click on feature
 *	@param {bool} options.translate Can translate the feature
 *  @param {bool} options.translateBBox Enable translate when the user drags inside the bounding box
 *	@param {bool} options.stretch can stretch the feature
 *	@param {bool} options.scale can scale the feature
 *	@param {bool} options.rotate can rotate the feature
 *	@param {bool} options.noFlip prevent the feature geometry to flip, default false
 *	@param {bool} options.selection the intraction handle selection/deselection, if not use the select prototype to add features to transform, default true
 *	@param {ol.events.ConditionType | undefined} options.keepAspectRatio A function that takes an ol.MapBrowserEvent and returns a boolean to keep aspect ratio, default ol.events.condition.shiftKeyOnly.
 *	@param {ol.events.ConditionType | undefined} options.modifyCenter A function that takes an ol.MapBrowserEvent and returns a boolean to apply scale & strech from the center, default ol.events.condition.metaKey or ol.events.condition.ctrlKey.
 *	@param {boolean} options.enableRotatedTransform Enable transform when map is rotated
 *	@param {boolean} [options.keepRectangle=false] keep rectangle when possible
 *  @param {number} [options.buffer] the buffer in meters to increase the extent used as bounding box with, default 0. Or a function that takes a feature and returns the buffer (or [bufferX, bufferY]). If not null show handles to transform the lines and polygons.
 *	@param {*} options.style list of ol.style for handles
 *  @param {number|Array<number>|function} [options.pointRadius=0] radius for points or a function that takes a feature and returns the radius (or [radiusX, radiusY]). If not null show handles to transform the points.
 */
const ol_interaction_Transform = class olinteractionTransform extends ol_interaction_Pointer {
  constructor(options) {
    options = options || {};
    // Extend pointer
    super({
      handleDownEvent(e) {
        return self.handleDownEvent_(e);
      },
      handleDragEvent(e) {
        return this.handleDragEvent_(e);
      },
      handleMoveEvent(e) {
        return this.handleMoveEvent_(e);
      },
      handleUpEvent(e) {
        return this.handleUpEvent_(e);
      },
    });

    var self = this;
    this.selection_ = new ol_Collection();

    // Create a new overlay layer for the sketch
    this.handles_ = new ol_Collection();
    this.overlayLayer_ = new ol_layer_Vector({
      displayInLayerSwitcher: false,
      name: "Transform overlay",
      source: new ol_source_Vector({
        features: this.handles_,
        useSpatialIndex: false,
        wrapX: false, // For vector editing across the -180° and 180° meridians to work properly, this should be set to false
      }),
      // Return the style according to the handle type
      style(feature) {
        const handleStyle =
          self.style[
            (feature.get("handle") || "default") +
              (feature.get("constraint") || "") +
              (feature.get("option") || "")
          ];
        if (self.ispt_ && feature.get("handle") === "rotate") {
          const style = getFeatureStyle(self.selection_.item(0));
          const styleWithRotation = style?.getText() || style?.getImage();
          let rotation = styleWithRotation?.getRotation() || 0;
          const rotateWithView = styleWithRotation?.getRotateWithView();

          rotation = rotateWithView
            ? rotation + self.getMap().getView().getRotation()
            : rotation;

          handleStyle?.[0]?.getImage()?.setRotation(rotation);
        }
        return handleStyle;
      },
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });

    // Collection of feature to transform
    this.features_ = options.features;
    // Filter or list of layers to transform
    if (typeof options.filter === "function") this._filter = options.filter;
    this.layers_ = options.layers
      ? options.layers instanceof Array
        ? options.layers
        : [options.layers]
      : null;

    this._handleEvent =
      options.condition ||
      function () {
        return true;
      };
    this.addFn_ =
      options.addCondition ||
      function () {
        return false;
      };
    this.setPointRadius(options.pointRadius);

    /* Add buffer to the feature's extent */
    this.setBuffer(options.buffer);

    /* Translate when click on feature */
    this.set("translateFeature", options.translateFeature !== false);
    /* Can translate the feature */
    this.set("translate", options.translate !== false);
    /* Translate when click on the bounding box */
    this.set("translateBBox", options.translateBBox === true);
    /* Can stretch the feature */
    this.set("stretch", options.stretch !== false);
    /* Can scale the feature */
    this.set("scale", options.scale !== false);
    /* Can rotate the feature */
    this.set("rotate", options.rotate !== false);
    /* Keep aspect ratio */
    this.set(
      "keepAspectRatio",
      options.keepAspectRatio ||
        ((e) => {
          return e.originalEvent.shiftKey;
        }),
    );
    /* Modify center */
    this.set(
      "modifyCenter",
      options.modifyCenter ||
        ((e) => {
          return e.originalEvent.metaKey || e.originalEvent.ctrlKey;
        }),
    );
    /* Prevent flip */
    this.set("noFlip", options.noFlip || false);
    /* Handle selection */
    this.set("selection", options.selection !== false);
    /*  */
    this.set("hitTolerance", options.hitTolerance || 0);
    /* Enable view rotated transforms */
    this.set("enableRotatedTransform", options.enableRotatedTransform || false);
    /* Keep rectangle angles 90 degrees */
    this.set("keepRectangle", options.keepRectangle || false);

    // Force redraw when changed
    this.on("propertychange", function () {
      this.drawSketch_();
    });

    this.setDefaultStyle();
  }

  /**
   * @private
   */
  _countVector(start, end) {
    return [end[0] - start[0], end[1] - start[1]];
  }

  /** Test if rectangle
   * @param {ol.Geometry} geom
   * @returns {boolean}
   * @private
   */
  _isRectangle(geom) {
    if (this.get("keepRectangle") && geom.getType() === "Polygon") {
      const coords = geom.getCoordinates()[0];
      return coords.length === 5;
    }
    return false;
  }

  /**
   * @private
   */
  _movePoint(point, displacementVector) {
    return [point[0] + displacementVector[0], point[1] + displacementVector[1]];
  }

  /**
   * @private
   */
  _projectVectorOnVector(displacement_vector, base) {
    const k =
      (displacement_vector[0] * base[0] + displacement_vector[1] * base[1]) /
      (base[0] * base[0] + base[1] * base[1]);
    return [base[0] * k, base[1] * k];
  }

  /** Draw transform sketch
   * @param {boolean} draw only the center
   */
  drawSketch_(center) {
    let i;
    let f;
    let geom;
    const keepRectangle =
      this.selection_.item(0) &&
      this._isRectangle(this.selection_.item(0).getGeometry());
    this.overlayLayer_.getSource().clear();
    if (!this.selection_.getLength()) return;
    const viewRotation = this.getMap().getView().getRotation();
    let ext = this.getGeometryRotateToZero_(
      this.selection_.item(0),
    ).getExtent();
    let coords;
    if (keepRectangle) {
      coords = this.getGeometryRotateToZero_(this.selection_.item(0))
        .getCoordinates()[0]
        .slice(0, 4);
      coords.unshift(coords[3]);
    }
    const buffer =
      this.selection_.getLength() === 1
        ? this._buffer(this.selection_.item(0))
        : 0;
    // Clone and extend
    ext = ol_extent_buffer(ext, buffer || 0);
    this.selection_.forEach((f) => {
      const extendExt = this.getGeometryRotateToZero_(f).getExtent();
      ol_extent_extend(ext, extendExt);
    });

    let ptRadius =
      this.selection_.getLength() === 1
        ? this._pointRadius(this.selection_.item(0))
        : 0;

    if (ptRadius && !(ptRadius instanceof Array))
      ptRadius = [ptRadius, ptRadius];

    if (center === true) {
      if (!this.ispt_) {
        this.overlayLayer_.getSource().addFeature(
          new ol_Feature({
            geometry: new ol_geom_Point(this.center_),
            handle: "rotate0",
          }),
        );
        geom = ol_geom_Polygon_fromExtent(ext);
        if (this.get("enableRotatedTransform") && viewRotation !== 0) {
          geom.rotate(viewRotation, this.getMap().getView().getCenter());
        }
        f = this.bbox_ = new ol_Feature(geom);
        this.overlayLayer_.getSource().addFeature(f);
      }
    } else {
      if (this.ispt_) {
        // Calculate extent around the point
        const p = this.getMap().getPixelFromCoordinate(
          ol_extent_getCenter(ext),
        );
        if (p) {
          const dx = ptRadius ? ptRadius[0] || 10 : 10;
          const dy = ptRadius ? ptRadius[1] || 10 : 10;
          ext = ol_extent_boundingExtent([
            this.getMap().getCoordinateFromPixel([p[0] + dx, p[1] - dy]),
            this.getMap().getCoordinateFromPixel([p[0] - dx, p[1] - dy]),
            this.getMap().getCoordinateFromPixel([p[0] - dx, p[1] + dy]),
            this.getMap().getCoordinateFromPixel([p[0] + dx, p[1] + dy]),
            this.getMap().getCoordinateFromPixel([p[0] + dx, p[1] - dy]),
          ]);
        }
      }
      geom = keepRectangle
        ? new ol_geom_Polygon([coords])
        : ol_geom_Polygon_fromExtent(ext);

      if (this.get("enableRotatedTransform") && viewRotation !== 0) {
        geom.rotate(viewRotation, this.getMap().getView().getCenter());
      }

      // When it's a point with a point radius defined we draw a box around the point
      // but this box follow the style rotation
      let isText = false;
      if (this.ispt_) {
        const feature = this.selection_.item(0);
        const style = getFeatureStyle(this.selection_.item(0));
        const ptCoords = feature.getGeometry().getCoordinates();
        const pp = this.getMap().getPixelFromCoordinate(ptCoords);
        const dx = ptRadius ? ptRadius[0] || 10 : 10;
        const dy = ptRadius ? ptRadius[1] || 10 : 10;

        isText = !!style?.getText()?.getText();

        coords = [
          this.getMap().getCoordinateFromPixel([pp[0] + dx, pp[1] - dy]),
          this.getMap().getCoordinateFromPixel([pp[0] - dx, pp[1] - dy]),
          this.getMap().getCoordinateFromPixel([pp[0] - dx, pp[1] + dy]),
          this.getMap().getCoordinateFromPixel([pp[0] + dx, pp[1] + dy]),
          this.getMap().getCoordinateFromPixel([pp[0] + dx, pp[1] - dy]),
        ];
        geom = new ol_geom_Polygon([coords]);

        // Rotate the overlay
        const styleWithRotation = style?.getText() || style?.getImage();
        const rotation = styleWithRotation?.getRotation();
        const rotateWithView = styleWithRotation?.getRotateWithView();

        if (rotation) {
          geom.rotate(-rotation, ptCoords);
        }

        if (rotateWithView) {
          geom.rotate(-viewRotation, ptCoords);
        }
      }

      f = this.bbox_ = new ol_Feature(geom);
      const features = [];
      const g = geom.getCoordinates()[0];
      if (!this.ispt_ || ptRadius) {
        if (isText) {
          // No style for the box when it's a text, because we manage the selct style differently
          f.setStyle(() => {});
        }

        features.push(f);
        // Middle
        if (
          !this.iscircle_ &&
          !this.ispt_ &&
          this.get("stretch") &&
          this.get("scale")
        ) {
          for (i = 0; i < g.length - 1; i++) {
            const coord = [
              (g[i][0] + g[i + 1][0]) / 2,
              (g[i][1] + g[i + 1][1]) / 2,
            ];
            f = new ol_Feature({
              constraint: i % 2 ? "h" : "v",
              geometry: new ol_geom_Point(coord),
              handle: "scale",
              option: i,
            });
            features.push(f);
          }
        }
        // Handles
        if (this.get("scale"))
          for (i = 0; i < g.length - 1; i++) {
            f = new ol_Feature({
              geometry: new ol_geom_Point(g[i]),
              handle: "scale",
              option: i,
            });
            features.push(f);
          }
        // Center
        if (this.get("translate") && !this.get("translateFeature")) {
          f = new ol_Feature({
            geometry: new ol_geom_Point([
              (g[0][0] + g[2][0]) / 2,
              (g[0][1] + g[2][1]) / 2,
            ]),
            handle: "translate",
          });
          features.push(f);
        }
      }
      // Rotate
      if (!this.iscircle_ && this.get("rotate")) {
        const i = this.ispt_ ? 2 : 3;
        f = new ol_Feature({
          geometry: new ol_geom_Point([
            (g[i][0] + g[i + 1][0]) / 2,
            (g[i][1] + g[i + 1][1]) / 2,
          ]),
          handle: "rotate",
        });
        features.push(f);
      }
      // Add sketch
      this.overlayLayer_.getSource().addFeatures(features);
    }
  }

  /**
   * Get the rotation center
   * @return {ol.coordinate|undefined}
   */
  getCenter() {
    return this.get("center");
  }

  /** Get Feature at pixel
   * @param {ol.Pixel}
   * @return {ol.feature}
   * @private
   */
  getFeatureAtPixel_(pixel) {
    const self = this;
    return (
      this.getMap().forEachFeatureAtPixel(
        pixel,
        (feature, layer) => {
          let found = false;
          // Overlay ?
          if (!layer) {
            if (feature === self.bbox_) {
              if (self.get("translateBBox")) {
                return {
                  constraint: "",
                  feature,
                  handle: "translate",
                  option: "",
                };
              }
              return false;
            }
            self.handles_.forEach((f) => {
              if (f === feature) found = true;
            });
            if (found)
              return {
                constraint: feature.get("constraint"),
                feature,
                handle: feature.get("handle"),
                option: feature.get("option"),
              };
          }
          // No seletion
          if (!self.get("selection")) {
            // Return the currently selected feature the user is interacting with.
            if (
              self.selection_.getArray().some((f) => {
                return feature === f;
              })
            ) {
              return {
                feature,
                handle: self.get("translateFeature") ? "translate" : undefined,
              };
            }
            return null;
          }
          // filter condition
          if (self._filter) {
            if (self._filter(feature, layer)) return { feature };
            return null;
          }

          // feature belong to a layer
          if (self.layers_) {
            for (let i = 0; i < self.layers_.length; i++) {
              if (self.layers_[i] === layer) return { feature };
            }
            return null;
          }

          // feature in the collection
          if (self.features_) {
            self.features_.forEach((f) => {
              if (f === feature) found = true;
            });
            if (found) return { feature };
            return null;
          }

          // Others
          return { feature };
        },
        { hitTolerance: this.get("hitTolerance") },
      ) || {}
    );
  }

  /** Get the features that are selected for transform
   * @return ol.Collection
   */
  getFeatures() {
    return this.selection_;
  }

  /** Rotate feature from map view rotation
   * @param {ol.Feature} f the feature
   * @param {boolean} clone clone resulting geom
   * @param {ol.geom.Geometry} rotated geometry
   */
  getGeometryRotateToZero_(f, clone) {
    const origGeom = f.getGeometry();
    const viewRotation = this.getMap().getView().getRotation();
    if (viewRotation === 0 || !this.get("enableRotatedTransform")) {
      return clone ? origGeom.clone() : origGeom;
    }
    const rotGeom = origGeom.clone();
    rotGeom.rotate(viewRotation * -1, this.getMap().getView().getCenter());
    return rotGeom;
  }

  /**
   * @param {ol.MapBrowserEvent} evt Map browser event.
   * @return {boolean} `true` to start the drag sequence.
   * @private
   */
  handleDownEvent_(evt) {
    if (!this._handleEvent(evt, this.selection_)) return;
    const sel = this.getFeatureAtPixel_(evt.pixel);
    const { feature } = sel;
    if (
      this.selection_.getLength() &&
      this.selection_.getArray().indexOf(feature) >= 0 &&
      ((this.ispt_ && this.get("translate")) || this.get("translateFeature"))
    ) {
      sel.handle = "translate";
    }
    if (sel.handle) {
      this.mode_ = sel.handle;
      this.opt_ = sel.option;
      this.constraint_ = sel.constraint;
      // Save info
      const viewRotation = this.getMap().getView().getRotation();
      // Get coordinate of the handle (for snapping)
      this.coordinate_ = feature.get("handle")
        ? feature.getGeometry().getCoordinates()
        : evt.coordinate;
      this.pixel_ = this.getMap().getCoordinateFromPixel(this.coordinate_); // evt.pixel;
      this.geoms_ = [];
      this.rotatedGeoms_ = [];
      let extent = ol_extent_createEmpty();
      let rotExtent = ol_extent_createEmpty();
      for (var f, i = 0; (f = this.selection_.item(i)); i++) {
        this.geoms_.push(f.getGeometry().clone());
        extent = ol_extent_extend(extent, f.getGeometry().getExtent());
        if (this.get("enableRotatedTransform") && viewRotation !== 0) {
          const rotGeom = this.getGeometryRotateToZero_(f, true);
          this.rotatedGeoms_.push(rotGeom);
          rotExtent = ol_extent_extend(rotExtent, rotGeom.getExtent());
        }
      }
      this.extent_ = ol_geom_Polygon_fromExtent(extent).getCoordinates()[0];
      if (this.get("enableRotatedTransform") && viewRotation !== 0) {
        this.rotatedExtent_ =
          ol_geom_Polygon_fromExtent(rotExtent).getCoordinates()[0];
      }
      if (this.mode_ === "rotate") {
        this.center_ = this.getCenter() || ol_extent_getCenter(extent);
        // we are now rotating (cursor down on rotate mode), so apply the grabbing cursor
        const element = evt.map.getViewport();
        element.style.cursor = this.Cursors.rotate0;
        this.previousCursor_ = element.style.cursor;
      } else {
        if (this.mode_ === "translate") {
          // we are now translating (cursor down on rotate mode), so apply the grabbing cursor
          const element = evt.map.getViewport();
          element.style.cursor = this.Cursors.translate0;
          this.previousCursor_ = element.style.cursor;
        }
        this.center_ = ol_extent_getCenter(extent);
      }
      this.angle_ = Math.atan2(
        this.center_[1] - evt.coordinate[1],
        this.center_[0] - evt.coordinate[0],
      );

      this.dispatchEvent({
        coordinate: evt.coordinate,
        feature: this.selection_.item(0),
        features: this.selection_,
        pixel: evt.pixel,
        type: `${this.mode_}start`,
      });
      return true;
    }
    if (this.get("selection")) {
      if (feature) {
        if (!this.addFn_(evt)) this.selection_.clear();
        const index = this.selection_.getArray().indexOf(feature);
        if (index < 0) this.selection_.push(feature);
        else this.selection_.removeAt(index);
      } else {
        this.selection_.clear();
      }
      this.ispt_ =
        this.selection_.getLength() === 1
          ? this.selection_.item(0).getGeometry().getType() == "Point"
          : false;
      this.iscircle_ =
        this.selection_.getLength() === 1
          ? this.selection_.item(0).getGeometry().getType() == "Circle"
          : false;
      this.drawSketch_();
      this.watchFeatures_();
      this.dispatchEvent({
        coordinate: evt.coordinate,
        feature,
        features: this.selection_,
        pixel: evt.pixel,
        type: "select",
      });
      return false;
    }
  }

  /**
   * @param {ol.MapBrowserEvent} evt Map browser event.
   * @private
   */
  handleDragEvent_(evt) {
    if (!this._handleEvent(evt, this.features_)) return;
    const viewRotation = this.getMap().getView().getRotation();
    let i;
    let j;
    let f;
    let geometry;
    const pt0 = [this.coordinate_[0], this.coordinate_[1]];
    const pt = [evt.coordinate[0], evt.coordinate[1]];
    this.isUpdating_ = true;
    switch (this.mode_) {
      case "rotate": {
        const a = Math.atan2(this.center_[1] - pt[1], this.center_[0] - pt[0]);
        if (!this.ispt) {
          for (i = 0, f; (f = this.selection_.item(i)); i++) {
            geometry = this.geoms_[i].clone();
            geometry.rotate(a - this.angle_, this.center_);
            // bug: ol, bad calculation circle geom extent
            if (geometry.getType() == "Circle")
              geometry.setCenterAndRadius(
                geometry.getCenter(),
                geometry.getRadius(),
              );
            f.setGeometry(geometry);
          }
        }
        this.drawSketch_(true);
        this.dispatchEvent({
          angle: a - this.angle_,
          coordinate: evt.coordinate,
          feature: this.selection_.item(0),
          features: this.selection_,
          pixel: evt.pixel,
          type: "rotating",
        });
        break;
      }
      case "translate": {
        const deltaX = pt[0] - pt0[0];
        const deltaY = pt[1] - pt0[1];

        for (i = 0, f; (f = this.selection_.item(i)); i++) {
          f.getGeometry().translate(deltaX, deltaY);
        }
        this.handles_.forEach((f) => {
          f.getGeometry().translate(deltaX, deltaY);
        });

        this.coordinate_ = evt.coordinate;
        this.dispatchEvent({
          coordinate: evt.coordinate,
          delta: [deltaX, deltaY],
          feature: this.selection_.item(0),
          features: this.selection_,
          pixel: evt.pixel,
          type: "translating",
        });
        break;
      }
      case "scale": {
        let center = this.center_;
        if (this.get("modifyCenter")(evt)) {
          let extentCoordinates = this.extent_;
          if (this.get("enableRotatedTransform") && viewRotation !== 0) {
            extentCoordinates = this.rotatedExtent_;
          }
          center = extentCoordinates[(Number(this.opt_) + 2) % 4];
        }
        const keepRectangle =
          this.geoms_.length == 1 && this._isRectangle(this.geoms_[0]);
        const stretch = this.constraint_;
        const opt = this.opt_;

        let downCoordinate = this.coordinate_;
        let dragCoordinate = evt.coordinate;
        if (this.get("enableRotatedTransform") && viewRotation !== 0) {
          const downPoint = new ol_geom_Point(this.coordinate_);
          downPoint.rotate(viewRotation * -1, center);
          downCoordinate = downPoint.getCoordinates();

          const dragPoint = new ol_geom_Point(evt.coordinate);
          dragPoint.rotate(viewRotation * -1, center);
          dragCoordinate = dragPoint.getCoordinates();
        }

        let scx =
          (dragCoordinate[0] - center[0]) / (downCoordinate[0] - center[0]);
        let scy =
          (dragCoordinate[1] - center[1]) / (downCoordinate[1] - center[1]);
        let displacementVector = [
          dragCoordinate[0] - downCoordinate[0],
          dragCoordinate[1] - downCoordinate[1],
        ];

        if (this.get("enableRotatedTransform") && viewRotation !== 0) {
          const centerPoint = new ol_geom_Point(center);
          centerPoint.rotate(
            viewRotation * -1,
            this.getMap().getView().getCenter(),
          );
          center = centerPoint.getCoordinates();
        }

        if (this.get("noFlip")) {
          if (scx < 0) scx = -scx;
          if (scy < 0) scy = -scy;
        }

        if (this.constraint_) {
          if (this.constraint_ == "h") scx = 1;
          else scy = 1;
        } else if (this.get("keepAspectRatio")(evt)) {
          scx = scy = Math.min(scx, scy);
        }

        for (i = 0, f; (f = this.selection_.item(i)); i++) {
          geometry =
            viewRotation === 0 || !this.get("enableRotatedTransform")
              ? this.geoms_[i].clone()
              : this.rotatedGeoms_[i].clone();
          geometry.applyTransform((g1, g2, dim) => {
            if (dim < 2) return g2;

            if (!keepRectangle) {
              for (j = 0; j < g1.length; j += dim) {
                if (scx != 1) g2[j] = center[0] + (g1[j] - center[0]) * scx;
                if (scy != 1)
                  g2[j + 1] = center[1] + (g1[j + 1] - center[1]) * scy;
              }
            } else {
              const pointArray = [[6], [0, 8], [2], [4]];
              const pointA = [g1[0], g1[1]];
              const pointB = [g1[2], g1[3]];
              const pointC = [g1[4], g1[5]];
              const pointD = [g1[6], g1[7]];
              const pointA1 = [g1[8], g1[9]];

              if (stretch) {
                const base =
                  opt % 2 === 0
                    ? this._countVector(pointA, pointB)
                    : this._countVector(pointD, pointA);
                const projectedVector = this._projectVectorOnVector(
                  displacementVector,
                  base,
                );
                const nextIndex = opt + 1 < pointArray.length ? opt + 1 : 0;
                const coordsToChange = [
                  ...pointArray[opt],
                  ...pointArray[nextIndex],
                ];

                for (j = 0; j < g1.length; j += dim) {
                  g2[j] = coordsToChange.includes(j)
                    ? g1[j] + projectedVector[0]
                    : g1[j];
                  g2[j + 1] = coordsToChange.includes(j)
                    ? g1[j + 1] + projectedVector[1]
                    : g1[j + 1];
                }
              } else {
                let projectedLeft;
                let projectedRight;
                switch (opt) {
                  case 0:
                    displacementVector = this._countVector(
                      pointD,
                      dragCoordinate,
                    );
                    projectedLeft = this._projectVectorOnVector(
                      displacementVector,
                      this._countVector(pointC, pointD),
                    );
                    projectedRight = this._projectVectorOnVector(
                      displacementVector,
                      this._countVector(pointA, pointD),
                    );
                    [g2[0], g2[1]] = this._movePoint(pointA, projectedLeft);
                    [g2[4], g2[5]] = this._movePoint(pointC, projectedRight);
                    [g2[6], g2[7]] = this._movePoint(
                      pointD,
                      displacementVector,
                    );
                    [g2[8], g2[9]] = this._movePoint(pointA1, projectedLeft);
                    break;
                  case 1:
                    displacementVector = this._countVector(
                      pointA,
                      dragCoordinate,
                    );
                    projectedLeft = this._projectVectorOnVector(
                      displacementVector,
                      this._countVector(pointD, pointA),
                    );
                    projectedRight = this._projectVectorOnVector(
                      displacementVector,
                      this._countVector(pointB, pointA),
                    );
                    [g2[0], g2[1]] = this._movePoint(
                      pointA,
                      displacementVector,
                    );
                    [g2[2], g2[3]] = this._movePoint(pointB, projectedLeft);
                    [g2[6], g2[7]] = this._movePoint(pointD, projectedRight);
                    [g2[8], g2[9]] = this._movePoint(
                      pointA1,
                      displacementVector,
                    );
                    break;
                  case 2:
                    displacementVector = this._countVector(
                      pointB,
                      dragCoordinate,
                    );
                    projectedLeft = this._projectVectorOnVector(
                      displacementVector,
                      this._countVector(pointA, pointB),
                    );
                    projectedRight = this._projectVectorOnVector(
                      displacementVector,
                      this._countVector(pointC, pointB),
                    );
                    [g2[0], g2[1]] = this._movePoint(pointA, projectedRight);
                    [g2[2], g2[3]] = this._movePoint(
                      pointB,
                      displacementVector,
                    );
                    [g2[4], g2[5]] = this._movePoint(pointC, projectedLeft);
                    [g2[8], g2[9]] = this._movePoint(pointA1, projectedRight);
                    break;
                  case 3:
                    displacementVector = this._countVector(
                      pointC,
                      dragCoordinate,
                    );
                    projectedLeft = this._projectVectorOnVector(
                      displacementVector,
                      this._countVector(pointB, pointC),
                    );
                    projectedRight = this._projectVectorOnVector(
                      displacementVector,
                      this._countVector(pointD, pointC),
                    );
                    [g2[2], g2[3]] = this._movePoint(pointB, projectedRight);
                    [g2[4], g2[5]] = this._movePoint(
                      pointC,
                      displacementVector,
                    );
                    [g2[6], g2[7]] = this._movePoint(pointD, projectedLeft);
                    break;
                }
              }
            }

            // bug: ol, bad calculation circle geom extent
            if (geometry.getType() == "Circle")
              geometry.setCenterAndRadius(
                geometry.getCenter(),
                geometry.getRadius(),
              );
            return g2;
          });
          if (this.get("enableRotatedTransform") && viewRotation !== 0) {
            // geometry.rotate(viewRotation, rotationCenter);
            geometry.rotate(viewRotation, this.getMap().getView().getCenter());
          }
          f.setGeometry(geometry);
        }
        this.drawSketch_();
        this.dispatchEvent({
          coordinate: evt.coordinate,
          feature: this.selection_.item(0),
          features: this.selection_,
          pixel: evt.pixel,
          scale: [scx, scy],
          type: "scaling",
        });
        break;
      }
      default:
        break;
    }
    this.isUpdating_ = false;
  }

  /**
   * @param {ol.MapBrowserEvent} evt Event.
   * @private
   */
  handleMoveEvent_(evt) {
    if (!this._handleEvent(evt, this.features_)) return;
    if (!this.mode_) {
      const sel = this.getFeatureAtPixel_(evt.pixel);
      const element = evt.map.getViewport();
      if (sel.feature) {
        const c = sel.handle
          ? this.Cursors[
              (sel.handle || "default") +
                (sel.constraint || "") +
                (sel.option || "")
            ]
          : this.get("selection")
            ? this.othis.Cursors.select
            : this.previousCursor_;

        if (this.previousCursor_ === undefined) {
          this.previousCursor_ = element.style.cursor;
        }
        element.style.cursor = c;
      } else {
        if (this.previousCursor_ !== undefined)
          element.style.cursor = this.previousCursor_;
        this.previousCursor_ = undefined;
      }
    }
  }

  /**
   * @param {ol.MapBrowserEvent} evt Map browser event.
   * @return {boolean} `false` to stop the drag sequence.
   */
  handleUpEvent_(evt) {
    // remove rotate0 cursor on Up event, otherwise it's stuck on grab/grabbing
    if (this.mode_ === "rotate") {
      const element = evt.map.getViewport();
      if (!this.Cursors.default) {
        delete element.style.cursor;
      } else {
        element.style.cursor = this.Cursors.default;
      }
      this.previousCursor_ = undefined;
    }

    if (this.mode_ === "translate") {
      const element = evt.map.getViewport();
      element.style.cursor = this.Cursors.default;
      this.previousCursor_ = undefined;
    }

    // dispatchEvent
    this.dispatchEvent({
      feature: this.selection_.item(0),
      features: this.selection_,
      oldgeom: this.geoms_[0],
      oldgeoms: this.geoms_,
      type: `${this.mode_}end`,
    });

    this.drawSketch_();
    this.mode_ = null;
    return false;
  }

  /** Select a feature to transform
   * @param {ol.Feature} feature the feature to transform
   * @param {boolean} add true to add the feature to the selection, default false
   */
  select(feature, add) {
    if (!feature) {
      if (this.selection_) {
        this.selection_.clear();
        this.drawSketch_();
      }
      return;
    }
    if (!feature.getGeometry || !feature.getGeometry()) return;
    // Add to selection
    if (add) {
      this.selection_.push(feature);
    } else {
      const index = this.selection_.getArray().indexOf(feature);
      this.selection_.removeAt(index);
    }
    this.ispt_ =
      this.selection_.getLength() === 1
        ? this.selection_.item(0).getGeometry().getType() == "Point"
        : false;
    this.iscircle_ =
      this.selection_.getLength() === 1
        ? this.selection_.item(0).getGeometry().getType() == "Circle"
        : false;
    this.drawSketch_();
    this.watchFeatures_();
    // select event
    this.dispatchEvent({
      feature,
      features: this.selection_,
      type: "select",
    });
  }

  /**
   * Activate/deactivate interaction
   * @param {bool}
   * @api stable
   */
  setActive(b) {
    this.select(null);
    if (this.overlayLayer_) this.overlayLayer_.setVisible(b);
    super.setActive(b);
  }

  /** Set the buffer  to calculate handles on lines and polygons
   *  @param {number|Array<number>|function} [buffer=0] rbuffer to extent the feature's bounding box with.
   */
  setBuffer(buffer) {
    if (typeof buffer === "function") {
      this._buffer = buffer;
    } else {
      this._buffer = function () {
        return buffer;
      };
    }
  }

  /**
   * Set the rotation center
   * @param {ol.coordinate|undefined} c the center point, default center on the objet
   */
  setCenter(c) {
    return this.set("center", c);
  }

  /** Set default sketch style
   * @param {Object} [options]
   *  @param {ol_style_Stroke} [stroke] stroke style for selection rectangle, default red dash
   *  @param {ol_style_Fill} [fill] fill style for selection rectangle, default red
   *  @param {ol_style_Stroke} [pointStroke] stroke style for handles, default red
   *  @param {ol_style_Fill} [pointFill] fill style for handles, default white
   */
  setDefaultStyle(options) {
    options = options || {};
    // Style
    const stroke =
      options.pointStroke ||
      new ol_style_Stroke({ color: [255, 0, 0, 1], width: 1 });
    const strokedash =
      options.stroke ||
      new ol_style_Stroke({
        color: [255, 0, 0, 1],
        lineDash: [4, 4],
        width: 1,
      });
    const fill0 =
      options.fill || new ol_style_Fill({ color: [255, 0, 0, 0.01] });
    const fill =
      options.pointFill || new ol_style_Fill({ color: [255, 255, 255, 0.8] });
    const circle = new ol_style_RegularShape({
      displacement: this.isTouch ? [0, -24] : [0, -24],
      fill,
      points: 15,
      radius: this.isTouch ? 12 : 8,
      stroke,
    });
    // Old version with no displacement
    if (!circle.setDisplacement)
      circle.getAnchor()[0] = this.isTouch ? -10 : -5;
    const bigpt = new ol_style_RegularShape({
      angle: Math.PI / 4,
      fill,
      points: 4,
      radius: this.isTouch ? 16 : 12,
      stroke,
    });
    const smallpt = new ol_style_RegularShape({
      angle: Math.PI / 4,
      fill,
      points: 4,
      radius: this.isTouch ? 12 : 10,
      stroke,
    });
    function createStyle(img, stroke, fill) {
      return [new ol_style_Style({ fill, image: img, stroke })];
    }
    /** Style for handles */
    this.style = {
      default: createStyle(bigpt, strokedash, fill0),
      rotate: createStyle(circle, stroke, fill),
      rotate0: createStyle(bigpt, stroke, fill),
      scale: createStyle(bigpt, stroke, fill),
      scale1: createStyle(bigpt, stroke, fill),
      scale2: createStyle(bigpt, stroke, fill),
      scale3: createStyle(bigpt, stroke, fill),
      scaleh1: createStyle(smallpt, stroke, fill),
      scaleh3: createStyle(smallpt, stroke, fill),
      scalev: createStyle(smallpt, stroke, fill),
      scalev2: createStyle(smallpt, stroke, fill),
      translate: createStyle(bigpt, stroke, fill),
    };
    this.drawSketch_();
  }

  /**
   * Remove the interaction from its current map, if any,  and attach it to a new
   * map, if any. Pass `null` to just remove the interaction from the current map.
   * @param {ol.Map} map Map.
   * @api stable
   */
  setMap(map) {
    const oldMap = this.getMap();
    if (oldMap) {
      const targetElement = oldMap.getViewport();
      oldMap.removeLayer(this.overlayLayer_);
      if (this.previousCursor_ && targetElement) {
        targetElement.style.cursor = this.previousCursor_;
      }
      this.previousCursor_ = undefined;
    }
    super.setMap(map);
    this.overlayLayer_.setMap(map);
    if (map === null) {
      this.select(null);
    }
    if (map !== null) {
      this.isTouch = /touch/.test(map.getViewport().className);
      this.setDefaultStyle();
    }
  }

  /** Set the point radius to calculate handles on points
   *  @param {number|Array<number>|function} [pointRadius=0] radius for points or a function that takes a feature and returns the radius (or [radiusX, radiusY]). If not null show handles to transform the points
   */
  setPointRadius(pointRadius) {
    if (typeof pointRadius === "function") {
      this._pointRadius = pointRadius;
    } else {
      this._pointRadius = function () {
        return pointRadius;
      };
    }
  }

  /** Update the selection collection.
   * @param {ol.Collection<ol.Feature>} features the features to transform
   */
  setSelection(features) {
    this.selection_.clear();
    features.forEach((feature) => {
      this.selection_.push(feature);
    });

    this.ispt_ =
      this.selection_.getLength() === 1
        ? this.selection_.item(0).getGeometry().getType() == "Point"
        : false;
    this.iscircle_ =
      this.selection_.getLength() === 1
        ? this.selection_.item(0).getGeometry().getType() == "Circle"
        : false;
    this.drawSketch_();
    this.watchFeatures_();
    // select event
    this.dispatchEvent({ features: this.selection_, type: "select" });
  }

  /**
   * Set sketch style.
   * @param {style} style Style name: 'default','translate','rotate','rotate0','scale','scale1','scale2','scale3','scalev','scaleh1','scalev2','scaleh3'
   * @param {ol.style.Style|Array<ol.style.Style>} olstyle
   * @api stable
   */
  setStyle(style, olstyle) {
    if (!olstyle) return;
    if (olstyle instanceof Array) this.style[style] = olstyle;
    else this.style[style] = [olstyle];
    for (let i = 0; i < this.style[style].length; i++) {
      const im = this.style[style][i].getImage();
      if (im) {
        if (style === "rotate") {
          im.getAnchor()[0] = -5;
        }
        if (this.isTouch) im.setScale(1.8);
      }
      const tx = this.style[style][i].getText();
      if (tx) {
        if (style === "rotate") tx.setOffsetX(this.isTouch ? 14 : 7);
        if (this.isTouch) tx.setScale(1.8);
      }
    }
    this.drawSketch_();
  }

  /** Watch selected features
   * @private
   */
  watchFeatures_() {
    // Listen to feature modification
    if (this._featureListeners) {
      this._featureListeners.forEach((l) => {
        ol_Observable_unByKey(l);
      });
    }
    this._featureListeners = [];
    this.selection_.forEach((f) => {
      this._featureListeners.push(
        f.on("change", () => {
          if (!this.isUpdating_) {
            this.drawSketch_();
          }
        }),
      );
    });
  }
};

/** Cursors for transform
 */
ol_interaction_Transform.prototype.Cursors = {
  default: "inherit",
  rotate: "move",
  rotate0: "move",
  scale: "nesw-resize",
  scale1: "nwse-resize",
  scale2: "nesw-resize",
  scale3: "nwse-resize",
  scaleh1: "ns-resize",
  scaleh3: "ns-resize",
  scalev: "ew-resize",
  scalev2: "ew-resize",
  select: "blabla",
  translate: "move",
  translate0: "move",
};

export default ol_interaction_Transform;
