import {
  RIGHT_MODUL_1,
  RIGHT_SET_DEFAULTS,
  RIGHT_SET_DEFAULTS_SU,
} from "./constants";

/**
 * This file contains all function to check if the user has the right to do something.
 *
 * If you have to add a new function, always define a constant variable containing the
 * identifer of the right then a function beginning by hasRightXXX(user).
 */

/**
 * This function take a right identifier regular expression
 * and a user and return true if the user has the right.
 * @param {RegExp} right
 * @param {User} user
 * @returns
 */
const hasRight = (rightRegEx, user) => {
  let tags = user?.profile?.tags || [];
  tags = tags && Array.isArray(tags) ? tags : [tags];
  return tags.some((tag) => rightRegEx.test(tag));
};

export const hasRightSetDefaults = (user) => {
  return (
    (hasRight(RIGHT_SET_DEFAULTS, user) ||
      hasRight(RIGHT_SET_DEFAULTS_SU, user)) &&
    hasRight(RIGHT_MODUL_1, user)
  );
};

// Momentan we use the same module name as default plan
export const hasRightLayoutExport = (user) => {
  return hasRight(RIGHT_MODUL_1, user);
};

export default hasRight;
