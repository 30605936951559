import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setDialogVisible } from "../../model/app/actions";
import { setBroadcast } from "../../model/oidc/actions";
import {
  BROADCAST_MSG_LOGIN,
  BROADCAST_MSG_LOGOUT,
  RELOAD_DIALOG_NAME,
  WARN_DIALOG_NAME,
} from "../constants";

/**
 * Opens a broadcast channel in browser to listen for login and logout events across browser tabs
 */
export default function useBroadcast() {
  const dispatch = useDispatch();
  const kmlAdminId = useSelector((state) => state.app.kmlAdminId);
  const dialogVisible = useSelector((state) => state.app.dialogVisible);
  const user = useSelector((state) => state.oidc.user);
  const broadcast = useSelector((state) => state.oidc.broadcast);
  const onBroadcastMessage = useCallback(
    (event) => {
      if (event.data === BROADCAST_MSG_LOGIN) {
        // User logged in in Tab 1 with warn/reload dialog open, login message from Tab 2
        if (
          user &&
          new RegExp(`${WARN_DIALOG_NAME}|${RELOAD_DIALOG_NAME}`).test(
            dialogVisible,
          )
        ) {
          dispatch(setDialogVisible(null));
        }
        // User logged out in Tab 1, login message from Tab 2
        if (!user && dialogVisible !== RELOAD_DIALOG_NAME) {
          dispatch(setDialogVisible(RELOAD_DIALOG_NAME));
        }
      }
      if (event.data === BROADCAST_MSG_LOGOUT) {
        // User logged in in Tab 1 (with/without loaded kml), logout message from Tab 2
        if (user) {
          dispatch(
            setDialogVisible(
              kmlAdminId ? WARN_DIALOG_NAME : RELOAD_DIALOG_NAME,
            ),
          );
        }
      }
    },
    [dialogVisible, dispatch, kmlAdminId, user],
  );

  useEffect(() => {
    if (!broadcast) {
      const bcChannel = new BroadcastChannel("auth_channel");
      bcChannel.onmessage = onBroadcastMessage;
      dispatch(setBroadcast(bcChannel));
    } else {
      broadcast.onmessage = onBroadcastMessage;
    }
  }, [broadcast, onBroadcastMessage, dispatch]);

  useEffect(() => {
    return () => {
      if (broadcast) {
        broadcast.close();
      }
    };
  }, [broadcast]);
}
