// Calculate the minimum size needed to be able to display the text on 1 or more lines.
const calculateTextWidth = (text, font = "8px Arial") => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  ctx.font = font;
  const { width } = ctx.measureText(text);
  return width;
};

export default calculateTextWidth;
