import { useMemo } from "react";
import { useSelector } from "react-redux";

import calculateTextWidth from "../calculateTextWidth";
import {
  EXTRA_DATA_COPYRIGHT_DEFAULT_FONTSIZE,
  EXTRA_DATA_COPYRIGHT_MIN_FONTSIZE,
  EXTRA_DATA_SCALE_THRESHOLD,
} from "../constants";
import { getLogoExtraData } from "../exportImageUtils";
import getCopyrightAsString from "../getCopyrightAsString";

function useIsExportSizeTooSmallForCopyright() {
  const layers = useSelector((state) => state.map.layers);
  const exportSize = useSelector((state) => state.app.exportSize);
  const exportLayout = useSelector((state) => state.app.exportLayout);
  const exportScale = useSelector((state) => state.app.exportScale);
  const scaleFactor = useMemo(() => {
    return exportSize && exportSize[1] < EXTRA_DATA_SCALE_THRESHOLD
      ? exportSize[1] / EXTRA_DATA_SCALE_THRESHOLD
      : 1;
  }, [exportSize]);
  const logoExtraData = useMemo(
    () => getLogoExtraData(scaleFactor, exportScale),
    [scaleFactor, exportScale],
  );

  const isExportSizeTooSmallForCopyright = useMemo(() => {
    let scaledCopyrightFontSize =
      EXTRA_DATA_COPYRIGHT_DEFAULT_FONTSIZE * scaleFactor;
    if (scaledCopyrightFontSize < EXTRA_DATA_COPYRIGHT_MIN_FONTSIZE) {
      scaledCopyrightFontSize = EXTRA_DATA_COPYRIGHT_MIN_FONTSIZE;
    }
    return (
      !exportLayout?.properties?.mapWidth &&
      exportSize &&
      exportSize[0] * exportScale - (logoExtraData.width + 10) <=
        calculateTextWidth(
          getCopyrightAsString(layers),
          `${Math.ceil(scaledCopyrightFontSize) * exportScale}px Arial`,
        ) /
          2 -
          10
    ); // 2: max nb of lines, 10: padding left + right
  }, [
    exportScale,
    exportSize,
    exportLayout,
    layers,
    scaleFactor,
    logoExtraData,
  ]);

  return isExportSizeTooSmallForCopyright;
}

export default useIsExportSizeTooSmallForCopyright;
