import { useMemo } from "react";
import { useSelector } from "react-redux";

import { LAYOUT_MIN_EXPORT_WIDTH } from "../constants";

// Warns the user that map elements might look off when the export frame is too small
const useIsExportWidthTooSmallForLayout = () => {
  const exportSize = useSelector((state) => state.app.exportSize);
  const exportScale = useSelector((state) => state.app.exportScale);
  const exportSelect = useSelector((state) => state.app.exportSelect);
  const exportLayoutHasMapWidth = useSelector(
    (state) => state.app.exportLayout?.properties?.mapWidth,
  );
  const isTooSmall = useMemo(() => {
    const [width] = exportSize || [];
    return exportSelect && exportLayoutHasMapWidth && width
      ? width * exportScale < LAYOUT_MIN_EXPORT_WIDTH * exportScale
      : false;
  }, [exportSize, exportScale, exportSelect, exportLayoutHasMapWidth]);
  return isTooSmall;
};

export default useIsExportWidthTooSmallForLayout;
