import { BROADCAST_MSG_LOGOUT } from "../../utils/constants";

export const SET_USER = "SET_USER";
export const SET_BROADCAST = "SET_BROADCAST";

export const setUser = (data) => ({
  data,
  type: SET_USER,
});

export const setBroadcast = (data) => ({
  data,
  type: SET_BROADCAST,
});

export const login = () => (dispatch, getState) => {
  const {
    app: { authUrl },
  } = getState();
  window.location.href = `${authUrl}/authenticate/?next=${encodeURIComponent(window.location.href)}`;
};

export const logout = () => (dispatch, getState) => {
  const {
    app: { authUrl },
    oidc: { broadcast },
  } = getState();

  if (broadcast) {
    broadcast.postMessage(BROADCAST_MSG_LOGOUT);
  }
  window.location.href = `${authUrl}/logout`;
};
