import { DEFAULTS_PATHNAME, PREVIEW_PATHNAME } from "./constants";

export function getIsPublic(hasParentOptions) {
  const regex = new RegExp(`(${PREVIEW_PATHNAME}|${DEFAULTS_PATHNAME})`);
  return !!hasParentOptions || regex.test(window.location.pathname);
}
/**
 * This function verify if the user's token is not expired before fetching the data.
 * @param {object} user User logged.
 * @param {string} func method to execute when the user is correctly signed in.
 */
export const authorizedFetch = async (user, func, hasParentOptions) => {
  return (user || getIsPublic(hasParentOptions)) && func();
};

/**
 * This function send a request then:
 *   - if the request succeeds, dispatch an XXX action with the result,
 *   - if the request fails, dispatch a XXX_ERROR if the request fails.
 * @param {string} url Url of the request.
 * @param {string} method method tof the request GET,POST,PUT...
 * @param {string} type Action's name to dispatch
 * @param {Object} body Body of the request to send, a plain js object or a FormData.
 */
export const fetchData = (
  url,
  method = "GET",
  body = null,
  headers = {},
  signal,
) => {
  const fetchParams = {
    body,
    headers: { ...headers, "Accept-Language": document.documentElement.lang },
    method,
    signal,
  };
  return fetch(url, fetchParams).then((res) => {
    if (!res.ok) {
      throw res;
    }
    const clone = res.clone();
    // If the server respond a good http response but without json or text content,
    // we return an empty object.
    return res.json().catch((err) => {
      if (err && err.name === "AbortError") {
        // ignore user abort request
        return null;
      }
      return clone.text().catch(() => {
        return Promise.reject(
          new Error("The response is not a json or a text."),
        );
      });
    });
  });
};

export default {
  authorizedFetch,
  fetchData,
};
