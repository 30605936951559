import { useEffect, useState } from "react";

function useHorizontalDragScroll(el) {
  const [dragging, setDragging] = useState();
  useEffect(() => {
    if (el) {
      const div = el;
      /* Add listener for horizontal scroll */
      div.addEventListener("wheel", (evt) => {
        evt.stopPropagation();
        div.scrollLeft += Math.sign(evt.deltaY) * 60;
        evt.preventDefault();
      });

      /* Add listeners for grab scroll */
      let isDown = false;
      let startX;
      let scrollLeft;

      div.addEventListener("mousedown", (e) => {
        isDown = true;
        startX = e.pageX - div.offsetLeft;
        scrollLeft = div.scrollLeft;
      });
      div.addEventListener("mouseleave", () => {
        isDown = false;
        setDragging(false);
      });
      div.addEventListener("mouseup", () => {
        isDown = false;
        setDragging(false);
      });
      div.addEventListener("mousemove", (e) => {
        if (!isDown) {
          return;
        }
        e.preventDefault();
        setDragging(true);
        const x = e.pageX - div.offsetLeft;
        // Apply new scrollLeft and speed up with * 3
        div.scrollLeft = scrollLeft - (x - startX) * 3;
      });

      // Clone replace div node to remove all listeners
      return () => div.parentNode.replaceChild(div.cloneNode(), div);
    }
    return () => {};
  }, [el]);
  return [dragging];
}

export default useHorizontalDragScroll;
