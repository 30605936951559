export default async function fetchUserInfo(userInfoUrl, options = {}) {
  let userInfo = null;
  try {
    userInfo = await fetch(userInfoUrl, options);
    if (userInfo.ok) {
      userInfo = await userInfo.json();
    } else {
      throw new Error("User not authenticated.");
    }
  } catch (err) {
    if (err && err.name === "AbortError") {
      // ignore user abort request
      return null;
    }
    // eslint-disable-next-line no-console
    console.error(err);
    return null;
  }
  return userInfo;
}
