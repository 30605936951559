import {
  AERIAL_LAYER_NAME,
  BASE_BRIGHT_NETZKARTE_MAPSET_STYLE,
  BASE_BRIGHT_NETZKARTE_SATURIERT_MAPSET_STYLE,
  BASE_LAYER_NAME,
  LS_HIDE_SBB_WELCOME,
  SATURATED_LAYER_NAME,
  SBBWELCOME_DIALOG_NAME,
  STYLE_REVIEW_PREFIX,
} from "../../utils/constants";
import colors from "./colors";
import favicon from "./favicon.png";
import iconCategories from "./icons.json";

const config = {
  allowedParentApplications:
    "https?://(maps|wkp)2?(\\.(dev|stag|prod))?\\.trafimage(\\.geops)?\\.ch",
  domains: ["ch"],
  font: "16px SBBWeb-Roman",
  iconCategories,
  iconGeneratorActive: true,
  id: "SBB",
  name: "SBB",
  slug: "sbb",
  ssoTags: ["tp.ch.sbb", "tp.ch.sbb-d"],
  ...colors,
  baseLayers: [
    {
      key: "ch.sbb.netzkarte",
      name: BASE_LAYER_NAME,
      style: `${STYLE_REVIEW_PREFIX}${BASE_BRIGHT_NETZKARTE_MAPSET_STYLE}`,
    },
    {
      key: "ch.sbb.netzkarte_saturiert",
      name: SATURATED_LAYER_NAME,
      style: `${STYLE_REVIEW_PREFIX}${BASE_BRIGHT_NETZKARTE_SATURIERT_MAPSET_STYLE}`,
    },
    {
      key: "world.geops.aerial_sbb",
      name: AERIAL_LAYER_NAME,
      style: `${STYLE_REVIEW_PREFIX}aerial_sbb`,
    },
  ],
  // Options relative the 'parent' parameters. Url, display sidebar elements ...
  getParentOptions: (parentUrl) => {
    // Parent options could be defined depending of the parent url value.
    // For now we set it up for trafimage.
    return {
      showWelcomeDialog: localStorage.getItem(LS_HIDE_SBB_WELCOME) !== "true",
      sideBarIcon: favicon,
      sideBarTitle: "Zeichnung in WKP anzeigen",
      url: decodeURIComponent(parentUrl),
      welcomeDialogName: SBBWELCOME_DIALOG_NAME,
    };
  },
};
export default config;
