// Add icon urls to iconCategories from the config
export const getIconCategoriesWithUrl = (
  config,
  imagesUrl,
  globalIcons = [],
) => {
  const cdCategories = (config.iconCategories || []).map((cat) => {
    return {
      ...cat,
      icons: cat.icons.map((icon) => ({ ...icon, cd: config.id })),
    };
  });

  // Merge CD categories with global categories
  const formatted = [...cdCategories, ...globalIcons].reduce(
    (merged, currentCat, idx, array) => {
      if (merged.find((cat) => cat.id === currentCat.id)) {
        return merged;
      }
      const duplicates = array.filter((cat) => cat.id === currentCat.id);
      const newCat =
        duplicates.length === 2
          ? {
              icons: [...duplicates[0].icons, ...duplicates[1].icons],
              id: currentCat.id,
              supplement: duplicates[0].supplement || duplicates[1].supplement,
            }
          : duplicates[0];
      return newCat && newCat.icons.length > 0 ? [...merged, newCat] : merged;
    },
    [],
  );
  for (let i = 0; i < formatted.length; i += 1) {
    const category = formatted[i];
    category.useColorOption = false;

    for (let j = 0; j < category.icons.length; j += 1) {
      const icon = category.icons[j];
      icon.url = icon.cd
        ? `${imagesUrl}${icon.cd}/${icon.id}.png`
        : `${imagesUrl}global_icons/${icon.id}.png`;
      icon.originalSize = [144, 144]; // for ie11
    }
  }
  return formatted;
};
export default getIconCategoriesWithUrl;
