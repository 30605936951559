const getExportSizeFromExportCoordinates = (exportCoordinates, map) => {
  if (exportCoordinates && map) {
    const pixels = exportCoordinates.map((coord) => {
      return map.getPixelFromCoordinate(coord);
    });

    if (!pixels[0]) {
      // When the map is not yet loaded, the pixels are not yet available.
      return null;
    }

    const minX = Math.min(...pixels.map(([x]) => x));
    const minY = Math.min(...pixels.map(([, y]) => y));
    const maxX = Math.max(...pixels.map(([x]) => x));
    const maxY = Math.max(...pixels.map(([, y]) => y));
    return [maxX - minX, maxY - minY];
  }
  return null;
};

export default getExportSizeFromExportCoordinates;
