import colors from "./colors";
import iconCategories from "./icons.json";

const config = {
  domains: ["ch"],
  font: "16px arial",
  iconCategories,
  iconGeneratorActive: true,
  id: "VBZ",
  name: "VBZ",
  slug: "vbz",
  ssoTags: ["tp.ch.vbz"],
  ...colors,
  pictures: [
    { id: "vbz_bus_v1.png" },
    { id: "vbz_double_bus_v1.png" },
    { id: "vbz_tram_v1.png" },
    { id: "vbz_tram_long_v1.png" },
  ],
};
export default config;
