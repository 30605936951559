// Always make sure red color is the first in the array (is selected for defaullt color for lines and polygons)
const defaultColors = [
  { border: "white", fill: [169, 73, 65, 1], hex: "#a94941", name: "red" },
  {
    border: "black",
    fill: [53, 53, 53, 1],
    hex: "#353535",
    name: "dark grey",
  },
  {
    border: "black",
    fill: [105, 135, 161, 1],
    hex: "#6987a1",
    name: "blue",
  },
  {
    border: "black",
    fill: [118, 184, 51, 1],
    hex: "#76b833",
    name: "green",
  },
  { border: "white", fill: [229, 172, 82, 1], hex: "#e5ac52", name: "orange" },
  {
    border: "white",
    fill: [239, 239, 239, 1],
    hex: "#efefef",
    name: "light grey",
  },
];

export default {
  colors: defaultColors,
  iconGeneratorColors: defaultColors,
  lineColors: defaultColors,
  polygonFillColors: defaultColors,
  polygonLineColors: defaultColors,
  textBgColors: [
    { fill: [255, 255, 255, 0.01], name: "none" },
    ...defaultColors,
  ],
};
