import environment from "../../envConfig";
import { LS_SNAPPING_TYPE } from "../../utils/constants";
import getCurrentBaseLayer from "../../utils/getCurrentBaseLayer";
import {
  SET_BASE_LAYER,
  SET_CENTER,
  SET_EXTENT,
  SET_LAYER_SERVICE,
  SET_LAYERS,
  SET_RESOLUTION,
  SET_ROTATION,
  SET_SNAPPING_TYPE,
  SET_STYLE_LAYERS,
  SET_ZOOM,
} from "./actions";

const initialState = environment.map;

export default function map(state = initialState, action) {
  switch (action.type) {
    case SET_STYLE_LAYERS:
      return {
        ...state,
        styleLayers: [...action.data],
      };
    case SET_CENTER:
      return {
        ...state,
        center: [...action.data],
      };
    case SET_RESOLUTION:
      return {
        ...state,
        resolution: action.data,
      };
    case SET_ZOOM:
      return {
        ...state,
        zoom: action.data,
      };
    case SET_EXTENT:
      return {
        ...state,
        extent: [...action.data],
      };
    case SET_ROTATION:
      return {
        ...state,
        rotation: action.data,
      };
    case SET_LAYER_SERVICE:
      return {
        ...state,
        layerService: action.data,
      };
    case SET_BASE_LAYER:
      state.layers
        .filter((layer) => layer.get("isBaseLayer"))
        .forEach((baselayer) => {
          // eslint-disable-next-line no-param-reassign
          baselayer.visible = action.data.key === baselayer.key;
        });
      return {
        ...state,
        baseLayer: action.data,
      };
    case SET_LAYERS: {
      const baseLayers = action.data.filter((layer) =>
        layer.get("isBaseLayer"),
      );
      const urlParams = new URLSearchParams(window.location.search);
      return {
        ...state,
        baseLayer: getCurrentBaseLayer(
          baseLayers,
          urlParams.get("baselayer") || baseLayers[0].key,
        ),
        layers: action.data,
      };
    }
    case SET_SNAPPING_TYPE:
      localStorage.setItem(LS_SNAPPING_TYPE, action.data);
      return {
        ...state,
        snappingType: action.data,
      };
    default:
      return {
        ...state,
      };
  }
}
