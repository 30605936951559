import "ol/ol.css";
import PropTypes from "prop-types";
import React, { lazy, Suspense } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import App from "./App";
import "./App.scss";
import "./Globals.scss";
import { DEFAULTS_PATHNAME, PREVIEW_PATHNAME } from "./utils/constants";

const propTypes = {
  permalink: PropTypes.string,
};

const Root = lazy(() => import("./components/Root"));
const PlanPreview = lazy(() => import("./components/PlanPreview"));
const PlanDefaults = lazy(() => import("./components/PlanDefaults"));

function AppRouter({ permalink = null }) {
  // TODO do we still need this?
  if (permalink) {
    // app is loaded as es6 module
    return (
      <App>
        <PlanPreview permalink={permalink} />
      </App>
    );
  }

  return (
    <Router>
      <Suspense fallback={<div />}>
        <Routes>
          <Route
            element={
              <App>
                <PlanPreview />
              </App>
            }
            exact
            path={PREVIEW_PATHNAME}
          />
          <Route
            element={
              <App>
                <PlanDefaults />
              </App>
            }
            exact
            path={DEFAULTS_PATHNAME}
          />
          <Route
            element={
              <App>
                <Root />
              </App>
            }
            exact
            path="/"
          />
          <Route element={<Navigate to="/" />} path="/*" />
        </Routes>
      </Suspense>
    </Router>
  );
}

AppRouter.propTypes = propTypes;

export default React.memo(AppRouter);
