import Cookies from "js-cookie";

import { authorizedFetch, fetchData, getIsPublic } from "../../utils/actions";
import {
  LS_CD_KEY,
  LS_KML_KEY,
  MAPSET_LAYOUT_MAP,
  MAPSET_LAYOUT_TITLE,
  MAX_ANON_USE,
  STATE_SAVE,
  STATE_SAVED,
  STATE_SAVING,
  WARN_DIALOG_NAME,
} from "../../utils/constants";
import download from "../../utils/download";
import getExportSizeFromExportCoordinates from "../../utils/getExportSizeFromExportCoordinates";
import getLayoutScreenshotUrl from "../../utils/getLayoutScreenshotUrl";
import { hasRightSetDefaults } from "../../utils/hasRight";
import writeKml from "../../utils/writeKml";

export const TEST_OPENID = "TEST_OPENID";
export const GET_KML = "GET_KML";
export const SAVE_KML = "SAVE_KML";
export const DELETE_KML = "DELETE_KML";
export const SET_SIDEBAR_OPEN = "SET_SIDEBAR_OPEN";
export const SET_DIALOG_VISIBLE = "SET_DIALOG_VISIBLE";
export const SET_DIALOG_POSITION = "SET_DIALOG_POSITION";
export const SET_DIALOG_SIZE = "SET_DIALOG_SIZE";
export const SET_ACTIVE_BUTTON = "SET_ACTIVE_BUTTON";
export const SET_SELECTED_FEATURE = "SET_SELECTED_FEATURE";
export const SET_ICONS_GROUP = "SET_ICONS_GROUP";
export const SET_EXPORT_SCALE = "SET_EXPORT_SCALE";
export const SET_EXPORT_SIZE = "SET_EXPORT_SIZE";
export const SET_EXPORT_SELECT = "SET_EXPORT_SELECT";
export const SET_EXPORT_NORTH_ARROW = "SET_NORTH_ARROW";
export const SET_EXPORT_COORDINATES = "SET_EXPORT_COORDINATES";
export const SET_EXPORT_QR_CODE = "SET_EXPORT_QR_CODE";
export const SET_EXPORT_LAYOUT = "SET_EXPORT_LAYOUT";
export const SET_EXPORT_LAYOUTS = "SET_EXPORT_LAYOUTS";
export const SET_SHORTENED_READ_LINKS = "SET_SHORTENED_READ_LINKS";
export const SET_SHORTENED_WRITE_LINKS = "SET_SHORTENED_WRITE_LINKS";
export const FETCH_ERROR = "FETCH_ERROR";
export const SET_SAVE_CONFIRM = "SET_SAVE_CONFIRM";
export const SET_BUS_LAYERS = "SET_BUS_LAYERS";
export const SET_TRAM_LAYERS = "SET_TRAM_LAYERS";
export const SET_BUS_FILTERS = "SET_BUS_FILTERS";
export const RESET_BUS_FILTERS = "RESET_BUS_FILTERS";
export const SET_KML_HISTORY = "SET_KML_HISTORY";
export const SET_USING_HISTORY = "SET_USING_HISTORY";
export const SET_CONFIG = "SET_CONFIG";
export const SET_CONFIGS = "SET_CONFIGS";
export const UNDO = "UNDO";
export const REDO = "REDO";
export const SET_PARENT = "SET_PARENT";
export const SET_SELECTED_PLAN = "SET_SELECTED_PLAN";
export const SET_DRAW_INFO = "SET_DRAW_INFO";
export const SET_DRAW_INFOS = "SET_DRAW_INFOS";
export const UPSERT_DRAW_INFO = "UPSERT_DRAW_INFO";
export const SET_INSPECT_MODE = "SET_INSPECT_MODE";
export const SET_ANON_COUNT = "SET_ANON_COUNT";
export const SET_EXPORT_FORMAT = "SET_EXPORT_FORMAT";
export const SET_DRAWINFO_ERROR = "SET_DRAWINFO_ERROR";
export const SET_EXPORT_LAYOUT_CONFIG = "SET_EXPORT_LAYOUT_CONFIG";
export const SET_BBOX_INTERACTING = "SET_BBOX_INTERACTING";
export const SET_DRAWING = "SET_DRAWING";
export const SET_DRAW_CONTROL_ACTIVE = "SET_DRAW_CONTROL_ACTIVE";
export const SET_EDITOR = "SET_EDITOR";
export const SET_EXPORT_BBOX_LOCKED_TO_MAP = "SET_EXPORT_BBOX_LOCKED_TO_MAP";
export const SET_CURRENT_SIDEBAR_WIDTH = "SET_CURRENT_SIDEBAR_WIDTH";
export const SET_CLIPBOARD_FEATURES = "SET_CLIPBOARD_FEATURES";
export const SET_CURRENT_GROUP_CONTROLS = "SET_CURRENT_GROUP_CONTROLS";
export const SET_EXPORT_TYPE = "SET_EXPORT_TYPE";
export const SET_APP_READY = "SET_APP_READY";

// Increase the anonymouse use counter of a specifiy feature determined by its id.
export const increaseAnonUse = (id) => {
  return (dispatch, getState) => {
    const {
      app: { countAnonUse = {} },
      oidc: { user },
    } = getState();

    const count = countAnonUse[id] || 0;

    if (user || count > MAX_ANON_USE) {
      return null;
    }

    return dispatch({
      data: { ...countAnonUse, [id]: count + 1 },
      type: SET_ANON_COUNT,
    });
  };
};

export const setDrawControlActive = (data) => ({
  data,
  type: SET_DRAW_CONTROL_ACTIVE,
});
export const setDrawing = (data) => ({ data, type: SET_DRAWING });
export const setExportScale = (data) => ({ data, type: SET_EXPORT_SCALE });

export const setExportSize = (data) => (dispatch, getState) => {
  const { exportSize } = getState().app;
  if (JSON.stringify(exportSize) !== JSON.stringify(data)) {
    dispatch({ data, type: SET_EXPORT_SIZE });
  }
};

export const setExportSelect = (data) => ({ data, type: SET_EXPORT_SELECT });
export const setExportNorthArrow = (data) => ({
  data,
  type: SET_EXPORT_NORTH_ARROW,
});

export const setExportQrCode = (data) => ({
  data,
  type: SET_EXPORT_QR_CODE,
});

export const setExportCoordinates = (data) => (dispatch, getState) => {
  const { exportCoordinates } = getState().app;
  const { olMap } = getState().map;
  const flatData = data?.flat();
  const flatExportCoordinates = exportCoordinates?.flat();

  if (JSON.stringify(flatExportCoordinates) !== JSON.stringify(flatData)) {
    let mustBePropagate = true;
    if (
      flatExportCoordinates &&
      flatData &&
      flatExportCoordinates.length === flatData.length
    ) {
      // It happens that when we move the map that the export coordinates changes slightly +/- 0.00001
      // We don't propagate the change if the difference is less than 0.00001, to avoid changing
      // the parameter in the url and confusing the user.
      mustBePropagate = flatData.find((coord, idx) => {
        return Math.abs(flatExportCoordinates[idx] - coord) > 0.00001;
      });
    }
    let thunk;
    if (mustBePropagate) {
      thunk = dispatch({
        data,
        type: SET_EXPORT_COORDINATES,
      });
    }
    const size = getExportSizeFromExportCoordinates(data, olMap);
    dispatch(setExportSize(size));
    return Promise.resolve(thunk);
  }
  return Promise.resolve();
};

export const setExportFormat = (data) => ({
  data,
  type: SET_EXPORT_FORMAT,
});

export const setExportLayouts = (data) => ({
  data,
  type: SET_EXPORT_LAYOUTS,
});

export const setExportLayout = (data) => (dispatch, getState) => {
  const {
    app: { exportFormat, exportScale, exportSelect, exportSize },
  } = getState();

  if (data) {
    const {
      properties: { formats, mapHeight: h, mapRatio, mapWidth: w, scales },
    } = data;
    const dfltFormat = (formats || [])[0];
    const dfltScale = (scales || [])[0];

    if ((!w || !h) && !exportSelect) {
      dispatch(setExportSize());
    }

    // If the current size doesn't respect the image ratio we apply the good ratio.
    if (
      w &&
      h &&
      (!exportSize || Math.abs(exportSize[0] / exportSize[1] - w / h) > 0.01)
    ) {
      if (!exportSize) {
        dispatch(setExportSize([w, h]));
      } else if (Math.abs(exportSize[0] / exportSize[1] - w / h) > 0.01) {
        dispatch(setExportSize([exportSize[0], exportSize[0] / mapRatio]));
      }
    }

    // We set the default format if the current one is not supported
    if (
      dfltFormat &&
      (!exportFormat ||
        !(formats || []).find((value) => exportFormat === value))
    ) {
      dispatch(setExportFormat(dfltFormat));
    }

    // We set the default format if the current one is not supported
    if (
      dfltScale &&
      (!exportScale || !(scales || []).find((value) => exportScale === value))
    ) {
      dispatch(setExportScale(dfltScale));
    }
  }

  return dispatch({ data, type: SET_EXPORT_LAYOUT });
};

export const setSelectedFeature = (data) => ({
  data,
  type: SET_SELECTED_FEATURE,
});

export const setIconsGroup = (data) => (dispatch, getState) => {
  const { iconsGroup: prevIconsGroup } = getState().app;

  // Make sure the array is really not the same
  if (
    prevIconsGroup?.length !== data?.length ||
    prevIconsGroup?.find((feature, idx) => feature !== data?.[idx]) ||
    data?.find((feature, idx) => feature !== prevIconsGroup?.[idx])
  ) {
    dispatch({
      data,
      type: SET_ICONS_GROUP,
    });
  }
};
export const setBboxInteracting = (data) => ({
  data,
  type: SET_BBOX_INTERACTING,
});

export const setDialogVisible =
  (dialogName, dialogData) => (dispatch, getState) => {
    const {
      app: { dialogVisible },
    } = getState();

    if (dialogName && dialogName !== dialogVisible) {
      increaseAnonUse(dialogName)(dispatch, getState);
    }

    return dispatch({
      data: dialogName && {
        dialogData,
        dialogVisible: dialogName,
      },
      type: SET_DIALOG_VISIBLE,
    });
  };

export const setDialogPosition = (data) => ({
  data,
  type: SET_DIALOG_POSITION,
});

export const setDialogSize = (data) => ({
  data,
  type: SET_DIALOG_SIZE,
});

export const setActiveButton = (data) => ({
  data,
  type: SET_ACTIVE_BUTTON,
});

export const setSidebarOpen = (data) => ({
  data,
  type: SET_SIDEBAR_OPEN,
});

export const deleteKml = (data) => ({
  data,
  type: DELETE_KML,
});

export const setShortenedReadLinks = (data) => ({
  data,
  type: SET_SHORTENED_READ_LINKS,
});

export const setShortenedWriteLinks = (data) => ({
  data,
  type: SET_SHORTENED_WRITE_LINKS,
});

export const setFetchError = (data, action) => ({
  action,
  data,
  type: FETCH_ERROR,
});

let saveConfirmTimeout;
let saveConfirmTimeout2;
export const setSaveConfirm = (data) => (dispatch) => {
  clearTimeout(saveConfirmTimeout);
  clearTimeout(saveConfirmTimeout2);

  if (data === STATE_SAVED) {
    // We put back the succes state during 1 seconds
    saveConfirmTimeout = setTimeout(() => {
      dispatch({
        data,
        type: SET_SAVE_CONFIRM,
      });
    }, 1000);
    // We put back the original state when nothing happens after 2000 ms
    saveConfirmTimeout = setTimeout(() => {
      dispatch(setSaveConfirm(STATE_SAVE));
    }, 2000);
    return;
  }

  dispatch({
    data,
    type: SET_SAVE_CONFIRM,
  });
};

export const setBusLayers = (data) => ({
  data,
  type: SET_BUS_LAYERS,
});

export const setTramLayers = (data) => ({
  data,
  type: SET_TRAM_LAYERS,
});

export const setBusFilters = (data) => ({
  data,
  type: SET_BUS_FILTERS,
});

export const resetBusFilters = (data) => ({
  data,
  type: RESET_BUS_FILTERS,
});

export const setKmlHistory = (data) => ({
  data,
  type: SET_KML_HISTORY,
});
export const setAppReady = (data) => ({
  data,
  type: SET_APP_READY,
});

export const setUsingHistory = (data) => ({
  data,
  type: SET_USING_HISTORY,
});

export const setConfig = (data) => {
  return {
    data,
    type: SET_CONFIG,
  };
};

export const setConfigs = (data) => {
  return {
    data,
    type: SET_CONFIGS,
  };
};

export const setInspectMode = (data) => ({
  data,
  type: SET_INSPECT_MODE,
});

export const undo = (data) => ({
  data,
  type: UNDO,
});

export const redo = (data) => ({
  data,
  type: REDO,
});

export const setDrawInfoError = (data) => ({
  data,
  type: SET_DRAWINFO_ERROR,
});

export const setExportLayoutConfig = (obj) => (dispatch, getState) => {
  const {
    app: { exportLayoutConfig },
  } = getState();

  // Update layout only when it has changed, we test the uri which is the identifier of a layout.
  if (obj.layout?.uri === exportLayoutConfig.layout?.uri) {
    // eslint-disable-next-line no-param-reassign
    delete obj.layout;
  }

  dispatch({
    data: {
      ...(exportLayoutConfig || {}),
      ...obj,
    },
    type: SET_EXPORT_LAYOUT_CONFIG,
  });
};
export const setEditor = (data) => ({
  data,
  type: SET_EDITOR,
});

export const setSelectedPlan = (data) => ({
  data,
  type: SET_SELECTED_PLAN,
});

export const setParent = (data) => ({
  data,
  type: SET_PARENT,
});

export const setExportBboxLockedToMap = (data) => ({
  data,
  type: SET_EXPORT_BBOX_LOCKED_TO_MAP,
});

export const setCurrentSidebarWidth = (data) => ({
  data,
  type: SET_CURRENT_SIDEBAR_WIDTH,
});

export const setClipboardFeatures = (data) => ({
  data,
  type: SET_CLIPBOARD_FEATURES,
});

export const setCurrentGroupControls = (data) => ({
  data,
  type: SET_CURRENT_GROUP_CONTROLS,
});

export const setExportType = (data) => ({
  data,
  type: SET_EXPORT_TYPE,
});

export const fetchDrawInfo = (apiUrl, user, id, uuid) => {
  const url = id
    ? `${apiUrl}/meta/info/${id}/`
    : `${apiUrl}/meta/info/?uuid=${uuid}`;
  return authorizedFetch(user, () => fetchData(url));
};

export const setDrawInfo = (data) => (dispatch, getState) => {
  const {
    app: { drawInfo, exportLayoutConfig },
  } = getState();
  if (
    exportLayoutConfig &&
    (!exportLayoutConfig[MAPSET_LAYOUT_TITLE] ||
      exportLayoutConfig[MAPSET_LAYOUT_TITLE] === drawInfo?.name) &&
    data.name
  ) {
    dispatch(
      setExportLayoutConfig({
        [MAPSET_LAYOUT_TITLE]: data.name,
      }),
    );
  }
  dispatch({
    data,
    type: SET_DRAW_INFO,
  });
};

/*
 * Get information of the current KML displayed.
 */
export const getDrawInfo = (uuid) => (dispatch, getState) => {
  const {
    app: { apiUrl, kmlAdminId },
    oidc: { user },
  } = getState();
  if (!user || (!uuid && !kmlAdminId)) {
    return;
  }
  // eslint-disable-next-line consistent-return
  return fetchDrawInfo(apiUrl, user, null, kmlAdminId || uuid)
    .then((data) => {
      const planInfo = data && !data.detail && data.results[0];
      if (planInfo && hasRightSetDefaults(user)) {
        document.title = planInfo.name || "mapset";
        dispatch(setDrawInfo(planInfo));
      }
      return planInfo;
    })
    .catch(() => {});
};

const fetchDrawInfos = (apiUrl, user, params = {}, signal) => {
  const finalParams = {
    ...params,
    has_name: true,
    search: params.search ? params.search : "",
  };
  const paramsString = new URLSearchParams(finalParams).toString();

  return authorizedFetch(user, () =>
    fetchData(
      `${apiUrl}/meta/info/?${paramsString}`,
      "GET",
      null,
      {
        "Accept-Language": document.documentElement.lang,
      },
      signal,
    ).catch((err) => {
      if (err.name === "AbortError") {
        return Promise.resolve();
      }
      throw err;
    }),
  );
};

export const getDrawInfos =
  (params = {}, signal) =>
  (dispatch, getState) => {
    const {
      app: { apiUrl },
      oidc: { user },
    } = getState();
    if (!user) {
      return Promise.resolve();
    }
    return fetchDrawInfos(apiUrl, user, params, signal).then((data) => {
      if (data) {
        dispatch({
          data,
          type: SET_DRAW_INFOS,
        });
      }
      return data;
    });
  };

export const deleteDrawInfo = (planId) => (dispatch, getState) => {
  const {
    app: { apiUrl },
    oidc: { user },
  } = getState();
  if (!user || !planId) {
    return Promise.resolve();
  }

  return fetch(`${apiUrl}/meta/info/${planId}`, {
    headers: {
      "Accept-Language": document.documentElement.lang,
      "X-CSRFToken": Cookies.get("csrftoken"),
    },
    method: "DELETE",
  }).then(() => {
    dispatch({ data: null, type: SET_SELECTED_PLAN });
  });
};

/*
 * Create (POST) or update (PUT) plan name in backend and set the draw info in redux
 */
export const upsertDrawInfo =
  (drawInfoToUpsert = {}) =>
  (dispatch, getState) => {
    const {
      app: { apiUrl, drawInfo = {}, kmlAdminId },
      oidc: { user },
    } = getState();

    if (!user || !kmlAdminId || (!drawInfoToUpsert && !drawInfo)) {
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete("draw.id");

    const deletingDefaultFor = drawInfoToUpsert?.default_for === null;

    const newDrawInfo = {
      ...drawInfo,
      uuid: kmlAdminId,
      ...drawInfoToUpsert,
      default_for: deletingDefaultFor
        ? null
        : {
            ...(drawInfo?.default_for || {}),
            ...(drawInfoToUpsert?.default_for || {}),
          },
    };

    if (!newDrawInfo.name) {
      newDrawInfo.name = "";
    }

    if (
      !newDrawInfo.default_for ||
      (newDrawInfo.default_for &&
        Object.keys(newDrawInfo.default_for).length === 0)
    ) {
      // Sending null value will remove the plan from the list of default plan
      newDrawInfo.default_for = null;
    }

    if (JSON.stringify(newDrawInfo) === JSON.stringify(drawInfo)) {
      return;
    }
    dispatch(setSaveConfirm(STATE_SAVING));
    fetch(
      `${apiUrl}/meta/info/${
        drawInfo?.id ? `${drawInfo.id}/?uuid=${drawInfo.uuid}` : ""
      }`,
      {
        body: JSON.stringify(newDrawInfo),
        headers: {
          "Accept-Language": document.documentElement.lang,
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
        method: drawInfo?.id ? "PUT" : "POST",
      },
    )
      .then((res) => {
        if (!res.ok) {
          return res.json().then((errorJson) => {
            throw errorJson;
          });
        }
        return res.json();
      })
      .then((data) => {
        if (data.name) {
          document.title = data.name;
        }
        dispatch(setDrawInfo(data));
        dispatch({ data: null, type: SET_DRAWINFO_ERROR });
        dispatch(setSaveConfirm(STATE_SAVED));
      })
      .catch((error) => {
        if (error) {
          dispatch(setSaveConfirm(STATE_SAVE));
          // Don't reset the draw info value, let the user a chance to fix the issues
          // dispatch(setDrawInfo({ ...drawInfo }));
          dispatch({ data: error, type: SET_DRAWINFO_ERROR });
        }
      });
  };

export const getOldKml = (id) => (dispatch, getState) => {
  const headers = {};
  const {
    app: { oldKmlUrl },
  } = getState();
  return fetchData(`${oldKmlUrl}/${id}`, "GET", null, headers)
    .then((data) => {
      dispatch(setFetchError(null));
      if (!data.admin_id) {
        return dispatch({ data, type: GET_KML });
      }
      return data;
    })
    .catch((res) => {
      dispatch({ data: { data: null }, type: GET_KML });
      return Promise.reject(
        dispatch(setFetchError(res.statusText || res.message, GET_KML)),
      );
    });
};

const abortControllersByUuid = {};

export const getKml = (uuid) => (dispatch, getState) => {
  if (uuid) {
    localStorage.removeItem(LS_KML_KEY);
    const {
      app: { apiUrl, kmlUrl, parentOptions },
      oidc: { user },
    } = getState();

    if (!user && uuid && !getIsPublic(parentOptions)) {
      // Throws warning when user tries to load a plan, but he's not logged in and it's not a public plan
      return Promise.resolve(dispatch(setDialogVisible(WARN_DIALOG_NAME)));
    }

    dispatch(setDialogVisible(null));
    const thunk = authorizedFetch(
      user,
      async () => {
        const headers = {
          "Content-Type": "application/json; charset=UTF-8",
        };

        if (abortControllersByUuid[uuid]) {
          abortControllersByUuid[uuid].abort();
        }
        abortControllersByUuid[uuid] = new AbortController();

        try {
          const data = await fetchData(
            `${kmlUrl}/${uuid}/`,
            "GET",
            null,
            headers,
            abortControllersByUuid[uuid].signal,
          );
          const newData = { ...data };
          if (uuid !== newData.read_id) {
            newData.admin_id = uuid;
          } else {
            // if we get the kml using an read_id we delete the admin_id.
            delete newData.admin_id;
          }
          if (newData.admin_id && user) {
            const planInfo = await fetchDrawInfo(
              apiUrl,
              user,
              null,
              newData.admin_id,
            ).then((res) => !res?.detail && res?.results[0]);
            if (planInfo) {
              if (!hasRightSetDefaults(user) && planInfo.default_for) {
                // eslint-disable-next-line no-throw-literal
                throw {
                  action: "GET_DEFAULT_PLAN",
                };
              }
              document.title = planInfo.name || "mapset";
              dispatch(setDrawInfo(planInfo));
            }
          }
          return dispatch({ data: newData, type: GET_KML });
        } catch (error) {
          if (error.name === "AbortError") {
            // ignore abort error
            return Promise.resolve();
          }
          dispatch({ data: { data: null }, type: GET_KML });
          return Promise.reject(
            dispatch(
              setFetchError(
                error.statusText || error.message,
                error.action || GET_KML,
              ),
            ),
          );
        }
      },
      !!parentOptions,
    );
    return thunk;
  }
  const localKml = localStorage.getItem(LS_KML_KEY);
  return Promise.resolve(
    localKml && dispatch({ data: { data: localKml }, type: GET_KML }),
  );
};

/**
 * This function save a kml string in the current plan.
 * @param {*} kmlString
 * @param {*} drawInfoToUpsert
 * @returns
 */
export const saveKml =
  (kmlString, drawInfoToUpsert) => (dispatch, getState) => {
    dispatch(setSaveConfirm(STATE_SAVING));
    const {
      app: { kmlAdminId, kmlUrl, metaKml, parentOptions },
      oidc: { user },
    } = getState();
    const kml = kmlString || localStorage.getItem(LS_KML_KEY);

    // Define headers
    const headers = {
      "Content-Type": "application/json",
    };

    // If a user is logged or if mapset has been opened by trafimage.
    if (kml && (user || parentOptions)) {
      const urlSuffix = kmlAdminId ? `${kmlAdminId}/` : "";
      // Define Url
      let url = `${kmlUrl}/${urlSuffix}`;

      if (!user && parentOptions) {
        // No user logged and mapset opened by trafimage
        url = `${kmlUrl}/${urlSuffix}`;
        headers.Authorization = `Token ${process.env.REACT_APP_ANONYMOUS_USER_TOKEN}`;
      }

      // User logged
      if (user && Cookies.get("csrftoken")) {
        headers["X-CSRFToken"] = Cookies.get("csrftoken");
      }

      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete("draw.id");

      // Define body
      const body = JSON.stringify({
        data: kml,
        queryparams: searchParams.toString(),
      });

      if (
        metaKml?.data &&
        kml === metaKml?.data &&
        searchParams.toString() === metaKml?.queryparams
      ) {
        // no need to save if the kml and queryparms hasn't changed
        dispatch(setSaveConfirm(STATE_SAVED));
        return Promise.resolve();
      }

      const thunk = authorizedFetch(
        user,
        () =>
          fetchData(url, kmlAdminId ? "PUT" : "POST", body, headers)
            .then((data) => {
              dispatch(setFetchError(null));
              dispatch(upsertDrawInfo(drawInfoToUpsert));
              dispatch(setSaveConfirm(STATE_SAVED));
              return dispatch({
                data,
                type: SAVE_KML,
              });
            })
            .catch((res) => {
              dispatch(setSaveConfirm(STATE_SAVED));
              return Promise.reject(
                dispatch(
                  setFetchError(res.statusText || res.message, SAVE_KML),
                ),
              );
            }),
        !!parentOptions,
      );
      thunk.meta = {
        debounce: { key: SAVE_KML, ...2000 },
      };
      localStorage.removeItem(LS_KML_KEY);
      return thunk;
    }
    if (kmlAdminId && !user) {
      dispatch(setSaveConfirm(STATE_SAVED));
      // Throws warning when user tries to save, but he's not logged in although there is a draw.id
      return dispatch({ data: WARN_DIALOG_NAME, type: "SET_DIALOG_VISIBLE" });
    }
    if (kml) {
      localStorage.setItem(LS_KML_KEY, kml);
      dispatch(setSaveConfirm(STATE_SAVED));
      return Promise.resolve(dispatch({ data: { data: kml }, type: SAVE_KML }));
    }
    dispatch(setSaveConfirm(STATE_SAVED));
    return Promise.resolve();
  };

const getDrawCopyIndex = async (apiUrl, user, originalName, signal) => {
  // Select the highest index of all the copies of a plan from the backend
  const promises = ["copy", "Kopie", "copie", "copia"].map((string) => {
    return fetchDrawInfos(
      apiUrl,
      user,
      { search: `${originalName} - ${string}` },
      signal,
    );
  });
  const index = await Promise.all(promises).then((values) => {
    return values.reduce((highestIdx, currentVal) => {
      const currentHighest = currentVal.results.reduce(
        (groupHighestIdx, plan) => {
          const idx = parseInt(
            plan?.name.split(/ - (copy|Kopie|copie|copia) - /)[2],
            10,
          );
          return Number.isInteger(idx) && idx > groupHighestIdx
            ? idx
            : groupHighestIdx;
        },
        0,
      );
      return currentHighest > highestIdx ? currentHighest : highestIdx;
    }, 0);
  });
  return index + 1;
};

// This method will save the current plan defined in the store.
export const savePlan = (drawInfoToUpsert) => (dispatch, getState) => {
  const {
    app: { kmlData },
  } = getState();

  return dispatch(saveKml(kmlData, drawInfoToUpsert));
};

export const copyPlan = (drawInfoToCopy, t) => async (dispatch, getState) => {
  const {
    app: { apiUrl, drawInfo, kmlUrl },
    oidc: { user },
  } = getState();

  const infoToCpy = drawInfoToCopy || drawInfo;
  if (!user) {
    // eslint-disable-next-line no-console
    console.error("You must be logged in to use the copyPlan function");
  }
  if (!infoToCpy) {
    // eslint-disable-next-line no-console
    console.error("The parameter drawInfoToCopy in copyPlan is mandatory");
  }

  const { name, uuid } = infoToCpy;

  const headers = {
    "Content-Type": "application/json",
    "X-CSRFToken": Cookies.get("csrftoken"),
  };

  // Get the kml data to copy (admin_id, read_id, ....)
  const kmlDataToCopy = await authorizedFetch(user, () =>
    fetchData(`${kmlUrl}/${uuid}`, "GET", null, headers),
  );

  // Copy and save the kml data.
  const kmlData = await authorizedFetch(user, () =>
    fetchData(`${kmlUrl}/`, "POST", JSON.stringify(kmlDataToCopy), headers),
  );

  // Copy and save the info metadata
  const [originalName] = name.split(/ - (copy|Kopie|copie|copia) - /);
  const drawInfoIndex = await getDrawCopyIndex(apiUrl, user, originalName);
  const newDrawInfo = {
    ...infoToCpy,
    default_for: null,
    name: `${originalName} - ${t("Kopie")} - ${drawInfoIndex}`,
    uuid: kmlData.admin_id,
  };
  return fetchData(
    `${apiUrl}/meta/info/`,
    "POST",
    JSON.stringify(newDrawInfo),
    headers,
  );
};

export const testOpenId = () => {
  const url = `https://geopstest.cloud.tyk.io/openid-test/get`;
  return fetchData(url, "GET", TEST_OPENID, null, {}, setFetchError);
};

/**
 * Export the selcted layout as pdf. The layout must be defined in jasper server.
 * @param {*} params
 * @returns
 */
export const exportJasperLayoutAsPdf =
  (signal, readUrl) => async (dispatch, getState) => {
    const {
      app: { exportLayout, exportLayoutConfig, jasperUrl },
    } = getState();

    if (!exportLayout) {
      return Promise.reject(new Error("No layout to export"));
    }

    const params = new URLSearchParams();

    Object.entries(exportLayout.variables).forEach(async ([name, type]) => {
      if (/(text|html)/.test(type)) {
        params.set(name, exportLayoutConfig[name] || "");
      }
    });

    // The previous forEach loop doesn't wait for async method.
    if (exportLayout.variables[MAPSET_LAYOUT_MAP]) {
      const url = await getLayoutScreenshotUrl(getState(), readUrl);
      params.set(MAPSET_LAYOUT_MAP, url);
    }

    try {
      const fetchParams = {
        signal,
      };
      const url = `${jasperUrl}/rest_v2/reports${
        exportLayout.uri
      }.pdf?${params.toString()}`;

      const resp = await fetch(url, fetchParams);
      if (resp.ok) {
        const data = await resp.blob();
        download(data, exportLayoutConfig[MAPSET_LAYOUT_TITLE]);
        return Promise.resolve();
      }

      // When a Jasper error occurs the mapset backend returns a json with the error in detail property.
      const data = await resp.json();

      // We try to parse the original jasper message and send it back to the user.
      const group = data?.detail?.match(/<message>(.*)<\/message>/);
      if (group?.length) {
        throw new Error(group[1]);
      } else {
        // Otherwise we display a basic message.
        throw new Error(
          "There was an error on the server. Try again or contact site administrators.",
        );
      }
    } catch (err) {
      if (err?.name !== "AbortError") {
        return Promise.reject(err);
      }
      return Promise.reject();
    }
  };

export const writeKmlHistory =
  // usingHistory should come from the store, but it's not updated properly so we pass it in as an argument
  (layer, usingHistory) => (dispatch, getState) => {
    const {
      app: { kmlHistory },
      map: { olMap: map, rotation },
    } = getState();
    if (usingHistory || kmlHistory?.usingHistory) {
      return;
    }
    const newPresentKml = writeKml(layer, map, rotation);
    if (
      (!kmlHistory.present && !kmlHistory.past.length) ||
      kmlHistory.present !== newPresentKml
    ) {
      dispatch(
        setKmlHistory({
          future: [],
          past: [...kmlHistory.past, kmlHistory.present],
          present: newPresentKml,
        }),
      );
    }
  };

export const setCorporateDesign = () => (dispatch, getState) => {
  /**
   * The CI uses gitlab@geops.de as user. Making alterations in this function
   * or in gitlab user's sso settings at https://sso.geops.io/admin/auth/user/?q=gitlab
   * may impact cypress tests which load a plan with text features, since the font type
   * depends on the CD.
   */
  const {
    app: { config, configs, parentOptions },
    oidc: { user },
  } = getState();
  if (config && parentOptions) {
    dispatch(setConfig(config));
    return;
  }
  const localStorageCD = localStorage.getItem(LS_CD_KEY);
  const tags = user?.profile?.tags || [];
  const ssoTags = Array.isArray(tags) ? tags : [tags];

  // Find the localstorage CD and check if user has access to it
  let authenticatedCD = configs.find(
    (cd) =>
      cd.ssoTags.includes(localStorageCD) && ssoTags?.includes(localStorageCD),
  );

  if (!authenticatedCD) {
    authenticatedCD = configs.find((cd) => {
      return ssoTags?.find((group) => cd.ssoTags.includes(group));
    });
  }

  // If no authenticated CD is found use default CD
  const finalCD = authenticatedCD || configs.find((cd) => cd.isDefault);
  if (finalCD) {
    dispatch(setConfig(finalCD));
    if (user) {
      localStorage.setItem(LS_CD_KEY, finalCD.ssoTags[0]);
    }
  }
};
