import { LineString } from "ol/geom";

import { LOAD_SIGNAL_ID } from "./constants";
import { getReadUrl } from "./getShortenedUrl";

/**
 *
 * This function returns a shortened link of the screenshot service url to use for pdf export.
 */
const getLayoutScreenshotUrl = (state, readUrl) => {
  const {
    domain,
    exportCoordinates,
    exportLayout,
    exportSize,
    kmlAdminId,
    kmlReadId,
    previewPathname,
    screenshotUrl,
  } = state.app;
  const { olMap } = state.map;

  const { mapHeight, mapWidth } = exportLayout.properties;
  const size = exportSize || [mapWidth, mapHeight];
  let extent;
  try {
    extent = exportCoordinates
      ? new LineString(exportCoordinates).getExtent()
      : olMap?.getView()?.calculateExtent(size);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log("No extent defined");
  }

  const newReadUrl =
    readUrl ||
    getReadUrl(
      // To facilitate the dev in frontend without installing the server. The screenshot service has no access to localhost.
      `${window.location.href.replace(
        /localhost:(3000|8080)/,
        `editor.dev.mapset.${domain}`,
      )}&mode=print&northarrow=true&scalebar=true`,
      kmlAdminId,
      kmlReadId,
      previewPathname,
      extent,
    );

  const width = size[0];
  const height = size[1];
  let deviceScaleFactor = 1;

  // If the width is not enough big to fit properly the template we increase the deviceScaleFactor
  if (mapWidth > 0) {
    while (
      width * deviceScaleFactor < mapWidth * 2.2 &&
      deviceScaleFactor < 5
    ) {
      deviceScaleFactor += 1;
    }
  }

  const params = new URLSearchParams({
    device_scale_factor: deviceScaleFactor,
    height: parseInt(height, 10),
    selector: `#${LOAD_SIGNAL_ID}`,
    url: newReadUrl,
    width: parseInt(width, 10), // Screenshot service manages only integer
  });
  const previewUrl = `${screenshotUrl}/screenshot?${params.toString()}`;
  return previewUrl;
};

export default getLayoutScreenshotUrl;
