import createBaseLayers from "./createBaseLayers";
import getCurrentBaseLayer from "./getCurrentBaseLayer";

export { default as createBaseLayers } from "./createBaseLayers";
export { default as getCurrentBaseLayer } from "./getCurrentBaseLayer";

export default {
  createBaseLayers,
  getCurrentBaseLayer,
};
