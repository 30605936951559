/* eslint-disable no-case-declarations */
import environment from "../../envConfig";
import {
  EXPORT_DIALOG_NAME,
  LS_COUNT_ANON_USE,
  LS_EXPORT_FORMAT,
  LS_EXPORT_QR_CODE,
  LS_KML_KEY,
  MAX_ANON_USE,
  ROUTE_FINDER_DIALOG_NAME,
} from "../../utils/constants";
import getDocumentTitle from "../../utils/getDocumentTitle";
import { getIconCategoriesWithUrl } from "../../utils/getIconCategories";
import globalIconsCh from "../../utils/globalIconsCh.json";
import globalIconsIo from "../../utils/globalIconsIo.json";
import {
  DELETE_KML,
  FETCH_ERROR,
  GET_KML,
  REDO,
  RESET_BUS_FILTERS,
  SAVE_KML,
  SET_ACTIVE_BUTTON,
  SET_ANON_COUNT,
  SET_APP_READY,
  SET_BBOX_INTERACTING,
  SET_BUS_FILTERS,
  SET_BUS_LAYERS,
  SET_CLIPBOARD_FEATURES,
  SET_CONFIG,
  SET_CONFIGS,
  SET_CURRENT_GROUP_CONTROLS,
  SET_CURRENT_SIDEBAR_WIDTH,
  SET_DIALOG_POSITION,
  SET_DIALOG_SIZE,
  SET_DIALOG_VISIBLE,
  SET_DRAW_CONTROL_ACTIVE,
  SET_DRAW_INFO,
  SET_DRAW_INFOS,
  SET_DRAWINFO_ERROR,
  SET_DRAWING,
  SET_EDITOR,
  SET_EXPORT_BBOX_LOCKED_TO_MAP,
  SET_EXPORT_COORDINATES,
  SET_EXPORT_FORMAT,
  SET_EXPORT_LAYOUT,
  SET_EXPORT_LAYOUT_CONFIG,
  SET_EXPORT_LAYOUTS,
  SET_EXPORT_NORTH_ARROW,
  SET_EXPORT_QR_CODE,
  SET_EXPORT_SCALE,
  SET_EXPORT_SELECT,
  SET_EXPORT_SIZE,
  SET_EXPORT_TYPE,
  SET_ICONS_GROUP,
  SET_INSPECT_MODE,
  SET_KML_HISTORY,
  SET_PARENT,
  SET_SAVE_CONFIRM,
  SET_SELECTED_FEATURE,
  SET_SELECTED_PLAN,
  SET_SHORTENED_READ_LINKS,
  SET_SHORTENED_WRITE_LINKS,
  SET_SIDEBAR_OPEN,
  SET_TRAM_LAYERS,
  SET_USING_HISTORY,
  TEST_OPENID,
  UNDO,
  UPSERT_DRAW_INFO,
} from "./actions";

const initialState = {
  ...environment.app,
  canAnonUseExport: false,
  canAnonUseRouteFinder: false,
  countAnonUse: JSON.parse(localStorage.getItem(LS_COUNT_ANON_USE)) || {},
  isAppReady: false, // We consider the app ready when a kml is ready and loaded or when there is no kml to load.
};

export default function app(state = initialState, action) {
  const {
    domain,
    kmlHistory: { future, past, present },
  } = state;
  let newPresent;

  switch (action.type) {
    case SET_CONFIG:
      return {
        ...state,
        config: action.data,
        iconCategories: getIconCategoriesWithUrl(
          action.data,
          state.imagesUrl,
          domain === "ch" ? globalIconsCh : globalIconsIo,
        ),
      };
    case SET_CONFIGS:
      return {
        ...state,
        configs: action.data,
      };
    case TEST_OPENID:
      return {
        ...state,
        testOpenIdResponse: action.data.testOpenIdResponse,
      };
    case SET_DIALOG_VISIBLE:
      return {
        ...state,
        dialogData: action.data?.dialogData,
        dialogVisible: action.data?.dialogVisible,
        previousDialog: state.dialogVisible,
      };
    case SET_ANON_COUNT:
      localStorage.setItem(LS_COUNT_ANON_USE, JSON.stringify(action.data));
      return {
        ...state,
        canAnonUseExport: action.data[EXPORT_DIALOG_NAME] <= MAX_ANON_USE,
        canAnonUseRouteFinder:
          action.data[ROUTE_FINDER_DIALOG_NAME] <= MAX_ANON_USE,
        countAnonUse: action.data,
      };
    case SET_DIALOG_POSITION:
      return {
        ...state,
        dialogPosition: {
          x: action.data.x,
          y: action.data.y,
        },
      };
    case SET_DIALOG_SIZE:
      return {
        ...state,
        dialogPosition: {
          x: action.data.x,
          y: action.data.y,
        },
        dialogSize: {
          height: action.data.height,
          width: action.data.width,
        },
      };
    case SET_SIDEBAR_OPEN:
      return {
        ...state,
        isSidebarOpen: action.data,
      };
    case SET_DRAWING:
      return {
        ...state,
        isDrawing: action.data,
      };
    case SET_DRAW_CONTROL_ACTIVE:
      return {
        ...state,
        isDrawControlActive: action.data,
      };
    case SET_BBOX_INTERACTING:
      return {
        ...state,
        isBboxInteracting: action.data,
      };
    case SET_EXPORT_BBOX_LOCKED_TO_MAP:
      return {
        ...state,
        exportBboxLockedToMap: action.data,
      };
    case SET_SELECTED_FEATURE:
      return {
        ...state,
        selectedFeature: action.data,
      };
    case SET_ICONS_GROUP:
      return {
        ...state,
        iconsGroup: action.data,
      };
    case SET_ACTIVE_BUTTON:
      return {
        ...state,
        activeButton: action.data,
      };
    case SET_APP_READY:
      return {
        ...state,
        isAppReady: action.data,
      };
    case GET_KML:
    case SAVE_KML:
      // eslint-disable-next-line no-case-declarations
      const newState = {
        ...state,
        kmlAdminId:
          action.data.admin_id === "hidden" ? undefined : action.data.admin_id,
        kmlData: action.data.data,
        kmlReadId: action.data.read_id,
        metaKml: action.data,
      };

      // Useful to determine when the KML has been loaded successfully or not
      if (action.type === GET_KML) {
        // If action.data !== null || undefined it means the KML is successfully loaded
        newState.isKmlReady = !!action.data?.data;

        // If action.data === null it means the KML has failed to load
        // If action.data === undefined it means the KML is not loaded yet
        newState.isAppReady = action.data?.data !== undefined;
      }

      return newState;
    case DELETE_KML:
      localStorage.removeItem(LS_KML_KEY);
      return {
        ...state,
      };
    case SET_DRAW_INFOS: {
      const { count, next, previous, results } = action.data;
      return {
        ...state,
        drawInfos: results?.length
          ? results.sort((a, b) => {
              return a.name.localeCompare(b.name);
            })
          : null,
        drawInfosCount: count,
        drawInfosNext: next,
        drawInfosPrevious: previous,
      };
    }
    case UPSERT_DRAW_INFO:
      return {
        ...state,
        drawInfo: action.data,
      };
    case SET_DRAW_INFO:
      document.title = getDocumentTitle(action.data);
      return {
        ...state,
        drawInfo: action.data,
      };
    case SET_SELECTED_PLAN:
      return {
        ...state,
        selectedPlan: action.data,
      };
    case SET_EXPORT_SCALE:
      return {
        ...state,
        exportScale: action.data,
      };
    case SET_EXPORT_SIZE:
      return {
        ...state,
        exportSize: action.data,
      };
    case SET_EXPORT_SELECT:
      return {
        ...state,
        exportSelect: action.data,
      };
    case SET_EXPORT_NORTH_ARROW:
      return {
        ...state,
        exportNorthArrow: action.data,
      };
    case SET_EXPORT_COORDINATES:
      return {
        ...state,
        exportCoordinates: action.data,
      };
    case SET_EXPORT_QR_CODE:
      localStorage.setItem(LS_EXPORT_QR_CODE, action.data);
      return {
        ...state,
        exportQrCode: action.data,
      };
    case SET_EXPORT_FORMAT:
      localStorage.setItem(LS_EXPORT_FORMAT, action.data);
      return {
        ...state,
        exportFormat: action.data,
      };
    case SET_EXPORT_LAYOUT:
      return {
        ...state,
        exportLayout: action.data,
      };
    case SET_EXPORT_LAYOUTS:
      return {
        ...state,
        exportLayouts: action.data,
      };
    case SET_SHORTENED_READ_LINKS:
      return {
        ...state,
        shortenedReadLinks: action.data,
      };
    case SET_SHORTENED_WRITE_LINKS:
      return {
        ...state,
        shortenedWriteLinks: action.data,
      };
    case FETCH_ERROR:
      return {
        ...state,
        fetchError: action.action,
      };
    case SET_SAVE_CONFIRM:
      return {
        ...state,
        saveConfirm: action.data,
      };
    case SET_BUS_LAYERS:
      return {
        ...state,
        busLayers: action.data,
      };
    case SET_TRAM_LAYERS:
      return {
        ...state,
        tramLayers: action.data,
      };
    case SET_BUS_FILTERS:
      return {
        ...state,
        busFilters: action.data,
      };
    case RESET_BUS_FILTERS:
      return {
        ...state,
        initialFilters: action.data,
      };
    case SET_KML_HISTORY:
      if (action.data.past.length === 50) {
        /* 
        Sets limit for past kml states: 
        Removes the oldest kml if array length equals 10.
        */
        action.data.past.shift();
      }
      return {
        ...state,
        kmlHistory: action.data,
      };
    case SET_USING_HISTORY:
      return {
        ...state,
        usingHistory: action.data,
      };
    case UNDO:
      if (past.length === 0) {
        return { ...state };
      }

      /*
       * Loads past kml state:
       * - Writes most recent past kml into present
       * - Removes most recent kml state from past array
       * - Pushes present kml to future array
       */
      newPresent = past.pop();
      return {
        ...state,
        kmlHistory: {
          future: [present, ...future],
          past: [...past],
          present: newPresent,
        },
        usingHistory: true,
      };
    case REDO:
      /* Loads future kml state (after using undo):
       * - Writes the oldest future kml into present
       * - Pushes present kml into past array
       * - Removes oldest future kml from future array
       */
      if (future.length === 0) {
        return { ...state };
      }
      newPresent = future.shift();
      return {
        ...state,
        kmlHistory: {
          future: [...future],
          past: [...past, present],
          present: newPresent,
        },
        usingHistory: true,
      };
    case SET_PARENT:
      return {
        ...state,
        parent: action.data,
      };
    case SET_INSPECT_MODE:
      return {
        ...state,
        inspectModeActive: action.data,
      };
    case SET_DRAWINFO_ERROR:
      return {
        ...state,
        drawInfoError: action.data,
      };
    case SET_EXPORT_LAYOUT_CONFIG:
      return {
        ...state,
        exportLayoutConfig: action.data,
      };
    case SET_EDITOR:
      return {
        ...state,
        editor: action.data,
      };
    case SET_CURRENT_SIDEBAR_WIDTH:
      return {
        ...state,
        currentSidebarWidth: action.data,
      };
    case SET_CLIPBOARD_FEATURES:
      return {
        ...state,
        clipboardFeatures: action.data,
      };
    case SET_CURRENT_GROUP_CONTROLS:
      return {
        ...state,
        currentGroupControls: action.data,
      };
    case SET_EXPORT_TYPE:
      return {
        ...state,
        exportType: action.data,
      };
    default:
      return {
        ...state,
      };
  }
}
