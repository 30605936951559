// Always make sure red color is the first in the array (is selected for defaullt color for lines and polygons)
const defaultColors = [
  { border: "white", fill: [240, 70, 69, 1], hex: "#f04645", name: "red" },
  { border: "white", fill: [224, 206, 170, 1], hex: "#e0ceaa", name: "beige" },
  { border: "white", fill: [45, 50, 125, 1], hex: "#2d327d", name: "blue" },
  { border: "black", fill: [0, 135, 90, 1], hex: "#00875a", name: "green" },
  { border: "white", fill: [0, 0, 0, 1], hex: "#000000", name: "black" },
  {
    border: "black",
    fill: [255, 255, 255, 1],
    hex: "#ffffff",
    name: "white",
  },
];

export default {
  colors: defaultColors,
  iconGeneratorColors: defaultColors,
  lineColors: defaultColors,
  polygonFillColors: defaultColors,
  polygonLineColors: defaultColors,
  textBgColors: [
    { fill: [255, 255, 255, 0.01], name: "none" },
    ...defaultColors,
  ],
};
