import colors from "./colors";
import iconCategories from "./icons.json";

const config = {
  domains: ["io"],
  font: "18px  monospace, serif",
  iconCategories,
  iconGeneratorActive: false,
  id: "io",
  isDefault: true,
  name: "IO",
  settings: {
    stops_api_prefagencies: "db,sbb",
  },
  slug: "mapsetio",
  ssoTags: ["default"],
  ...colors,
};

export default config;
