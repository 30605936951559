import colors from "./colors";
import iconCategories from "./icons.json";

const config = {
  domains: ["ch"],
  font: "16px arial",
  iconCategories,
  iconGeneratorActive: true,
  id: "RBS",
  name: "RBS",
  slug: "rbs",
  ssoTags: ["tp.ch.rbs"],
  ...colors,
  pictures: [{ id: "rbs_train_v1.png" }, { id: "rbs_bus_v1.png" }],
};
export default config;
