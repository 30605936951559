import { useMemo } from "react";
import { useSelector } from "react-redux";

import { EDIT_LAYER_NAME } from "../constants";

const useEditLayer = () => {
  const layerService = useSelector((state) => state.map.layerService);

  const layer = useMemo(() => {
    return layerService?.getLayer(EDIT_LAYER_NAME);
  }, [layerService]);
  return layer;
};

export default useEditLayer;
