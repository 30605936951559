import { useMemo } from "react";
import { useSelector } from "react-redux";

import useMaxCanvasSize from "./useMaxCanvasSize";

function useIsExportSizeTooBig() {
  const map = useSelector((state) => state.map.olMap);
  const exportSize = useSelector((state) => state.app.exportSize);
  const exportLayout = useSelector((state) => state.app.exportLayout);
  const exportScale = useSelector((state) => state.app.exportScale);
  const maxCanvasSize = useMaxCanvasSize();
  const size = useMemo(() => {
    return exportSize || map?.getSize();
  }, [exportSize, map]);

  // If the current layout is a jasper report, there is no limit of size.
  const isExportSizeTooBig = useMemo(() => {
    return (
      (!exportLayout?.properties?.mapWidth &&
        maxCanvasSize &&
        size &&
        (maxCanvasSize < size[0] * exportScale ||
          maxCanvasSize < size[1] * exportScale)) ||
      false
    );
  }, [exportLayout, maxCanvasSize, size, exportScale]);

  return isExportSizeTooBig;
}

export default useIsExportSizeTooBig;
