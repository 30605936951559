/* eslint-disable no-param-reassign */
import {
  Layer,
  MapboxStyleLayer as MbtMapboxStyleLayer,
} from "mobility-toolbox-js/ol";

import { MAPSET_METADATA_FILTER } from "./constants";

/**
 * Returns true if the layer contains the correct metadata.
 *
 * @param {Object} layer a mapbox style layer
 * @param {Regex} regex  The regex to test the metadata value with
 */
export const hasMetadataFilter = (
  layer,
  regex,
  filterAttribute = MAPSET_METADATA_FILTER,
) => {
  return (
    !!layer.metadata &&
    !!layer.metadata[filterAttribute] &&
    regex.test(layer.metadata[filterAttribute])
  );
};

/**
 * Get a specific layer from mapbox map, using the mapset.filter metadata value.
 * @param layer mapbox layer containing the mbMap
 * @param metadataValue Value fo the mapset.filter metadata property
 */
export const getStyleLayersByMetadataFilter = (
  layer,
  metadataValue,
  filterAttribute = MAPSET_METADATA_FILTER,
) => {
  if (!layer || !layer.mbMap) {
    return;
  }
  const styleObject = layer.mbMap.getStyle();
  const { layers } = styleObject;
  // eslint-disable-next-line consistent-return
  return layers.filter((styleLayer) => {
    return hasMetadataFilter(
      styleLayer,
      new RegExp(metadataValue),
      filterAttribute,
    );
  });
};

/**
 * Create filters for buslines
 */
export const createBusFilter = (filterItemsArray, lineProvidersArray) => {
  const customFilters = [];
  // eslint-disable-next-line array-callback-return
  filterItemsArray.map((f) => {
    if (f.lines !== "" && f.provider !== "") {
      customFilters.push(["in", f.lines, ["get", `lines ${f.provider}`]]);
    } else if (f.provider === "") {
      // Case for specified line only
      lineProvidersArray.forEach((i) =>
        customFilters.push(["in", f.lines, ["get", `lines ${i}`]]),
      );
    } else if (f.lines === "") {
      // Case for specified provider only
      customFilters.push(["has", `lines ${f.provider}`]);
    }
  });
  return customFilters;
};

class MapboxStyleLayer extends MbtMapboxStyleLayer {
  applyLayoutVisibility(evt) {
    try {
      // TODO: find why the style is not ready sometimes and triggers an ugly error
      super.applyLayoutVisibility(evt);
    } catch (err) {
      // console.error('Style is not ready', err);
    }
  }
}
/**
 *
 * @param {Object} options options for mobility-toolbox-js MapboxStyleLayer
 * @returns mobility-toolbox-js MapboxStyleLayer or Layer
 */
export const createMapboxStyleLayer = (options) => {
  const properties = {
    ...options,
    styleLayersFilter: (layer) => {
      return hasMetadataFilter(
        layer,
        options.properties?.filterRegex,
        options.properties?.filterAttribute,
      );
    },
  };
  if (options.children) {
    properties.children = options.children.map(createMapboxStyleLayer);
    return new Layer(properties);
  }
  return new MapboxStyleLayer(properties);
};

/**
 * @param {MapboxStyleLayer} layer The mobility-toolbox-js layer to set the mapbox layer
 * @param {MapboxLayer} mapboxLayer The mapbox layer to set
 */
export const setMapboxLayer = (layer, mapboxLayer) => {
  if (layer.children?.length > 0) {
    layer.children.forEach((child) => setMapboxLayer(child, mapboxLayer));
    return;
  }
  layer.mapboxLayer = mapboxLayer;
};
