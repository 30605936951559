import createBaseLayers from "../../utils/createBaseLayers";
import { setMapboxLayer } from "../../utils/mapboxStyle";

export const SET_CENTER = "SET_CENTER";
export const SET_LAYER_SERVICE = "SET_LAYER_SERVICE";
export const SET_RESOLUTION = "SET_RESOLUTION";
export const SET_ZOOM = "SET_ZOOM";
export const SET_STYLE_LAYERS = "SET_STYLE_LAYERS";
export const SET_EXTENT = "SET_EXTENT";
export const SET_ROTATION = "SET_ROTATION";
export const SET_BASE_LAYER = "SET_BASE_LAYER";
export const SET_SNAPPING_TYPE = "SET_SNAPPING_TYPE";
export const SET_BASE_LAYERS = "SET_BASE_LAYERS";
export const SET_LAYERS = "SET_LAYERS";
export const SET_MAX_EXTENT = "SET_MAX_EXTENT";

export const setBaseLayer = (data) => ({
  data,
  type: SET_BASE_LAYER,
});

export const setLayerService = (data) => ({
  data,
  type: SET_LAYER_SERVICE,
});

export const setStyleLayers = (data) => ({ data, type: SET_STYLE_LAYERS });

export const setCenter = (data) => ({ data, type: SET_CENTER });

export const setResolution = (data) => ({ data, type: SET_RESOLUTION });

export const setZoom = (data) => ({ data, type: SET_ZOOM });

export const setExtent = (data) => ({ data, type: SET_EXTENT });

export const setMaxExtent = (data) => ({ data, type: SET_MAX_EXTENT });

export const setRotation = (data) => ({ data, type: SET_ROTATION });

export const setBaseLayers = (data) => ({ data, type: SET_BASE_LAYERS });

export const setLayers = () => (dispatch, getState) => {
  const {
    app: { config, tilesKey, tilesUrl },
    map: { baseLayer, defaultBaseLayers, layers },
  } = getState();
  const newBaseLayers = createBaseLayers(
    [...(config.baseLayers || defaultBaseLayers)],
    { tilesKey, tilesUrl },
  );
  const otherLayers = layers.filter((layer) => !layer.get("isBaseLayer"));
  // Apply the current base layer to all the style layers.
  otherLayers.forEach((styleLayer) => {
    const baselayerr =
      newBaseLayers.find((bl) => bl.key === baseLayer.key) || newBaseLayers[0];
    setMapboxLayer(styleLayer, baselayerr);
  });
  return dispatch({
    data: [...newBaseLayers, ...otherLayers],
    type: SET_LAYERS,
  });
};

export const setSnappingType = (data) => ({
  data,
  type: SET_SNAPPING_TYPE,
});
