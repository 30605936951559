import { useMemo } from "react";
import { useSelector } from "react-redux";

import { MIME_TYPE_KML } from "../constants";

function useHasExportNoKmlData() {
  const kmlData = useSelector((state) => state.app.kmlData);
  const exportFormat = useSelector((state) => state.app.exportFormat);

  const hasExportNoKmlData = useMemo(() => {
    return [MIME_TYPE_KML].includes(exportFormat) && !kmlData; // 2: max nb of lines, 10: padding left + right
  }, [exportFormat, kmlData]);

  return hasExportNoKmlData;
}

export default useHasExportNoKmlData;
