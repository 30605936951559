import DrawControl from "ole/src/control/draw";

import ModifyControl from "../components/Editor/ModifyControl";

export const getModifyControl = (editor) =>
  editor?.controls?.getArray().find((c) => c instanceof ModifyControl);

export const getDrawControls = (editor) =>
  editor?.controls?.getArray().filter((c) => c instanceof DrawControl);

/**
 * Clear features in OLE select
 */
const clearSelectedFeatures = (editor) => {
  if (!editor) return;
  getModifyControl(editor)?.selectInteraction?.getFeatures().clear();
};

export default clearSelectedFeatures;
