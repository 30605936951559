import { unByKey } from "ol/Observable";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getDrawControls } from "../clearSelectedFeatures";

const useIsDrawing = () => {
  const editor = useSelector((state) => state.app.editor);
  const [isDrawing, setIsDrawing] = useState(false);

  useEffect(() => {
    const listenersKeys = [];

    if (!editor) {
      return () => {};
    }

    // Write history when an feature is drawn
    const drawControls = getDrawControls(editor);
    if (!drawControls?.length) {
      return () => {};
    }

    listenersKeys.push(
      ...drawControls.map((drawControl) => {
        return drawControl.drawInteraction.on("drawstart", () => {
          setIsDrawing(true);
        });
      }),
      ...drawControls.map((drawControl) => {
        return drawControl.drawInteraction.on("drawabort", () => {
          setIsDrawing(false);
        });
      }),
      ...drawControls.map((drawControl) => {
        return drawControl.drawInteraction.on("drawend", () => {
          setIsDrawing(false);
        });
      }),
    );

    return () => {
      unByKey(listenersKeys);
    };
  }, [editor]);

  return isDrawing;
};

export default useIsDrawing;
