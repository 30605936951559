import { cancelable } from "cancelable-promise";
import canvasSize from "canvas-size";
import { useEffect, useState } from "react";

import { LS_SIZE_KEY } from "../constants";

function useMaxCanvasSize() {
  const [maxCanvasSize, setMaxCanvasSize] = useState(
    localStorage.getItem(LS_SIZE_KEY) &&
      parseFloat(localStorage.getItem(LS_SIZE_KEY)),
  );

  useEffect(() => {
    const maxCanvasSizePromise =
      !maxCanvasSize &&
      cancelable(
        canvasSize
          .maxArea({ usePromise: true })
          .then((result) => {
            const size = Math.max(result.width, result.height);
            setMaxCanvasSize(size);
            localStorage.setItem(LS_SIZE_KEY, size);
          })
          .catch((result) =>
            // eslint-disable-next-line no-console
            console.log("Couldn't get maximum canvas", result),
          ),
      );
    return () => {
      if (maxCanvasSizePromise?.cancel) {
        maxCanvasSizePromise?.cancel();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return maxCanvasSize;
}

export default useMaxCanvasSize;
