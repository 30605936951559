import Collection from "ol/Collection";
import Modify from "ol/interaction/Modify";
import Select from "ol/interaction/Select";
import { unByKey } from "ol/Observable";
import Control from "ole/src/control/control";
import Delete from "ole/src/interaction/delete";

import TransformControl from "./TransformControl";

class ModifyControl extends Control {
  constructor(options = {}) {
    super(options);

    this.selectInteraction = new Select({
      style: null,
      ...(options.selectInteractionOptions || {}),
    });

    // Delete features with the delete or backsapce key
    this.deleteInteraction = new Delete(options.deleteInteractionOptions);

    // Transform interaction. Scale, rotate and move features.
    this.transformControl = new TransformControl({
      snapControl: options.snapControl,
    });
    this.snapControl = options.snapControl;

    this.options = options;
  }

  activate(features = []) {
    this.selectInteraction.getFeatures().clear();

    if (features?.length) {
      this.selectInteraction.getFeatures().extend(features);
    }

    if (this.getActive()) {
      this.onSelect(features);
      return;
    }
    super.activate();
    this.map?.addInteraction(this.selectInteraction);
    this.map?.addInteraction(this.deleteInteraction);

    this.olKeys = [
      // This event happens when the user click on a feature.
      this.selectInteraction.on("select", (evt) => {
        this.onSelect(evt.target.getFeatures().getArray());
      }),

      // Cursor style management
      this.map?.on("pointermove", (evt) => {
        const { map } = evt;
        const targetElement = evt.map.getTargetElement();
        const viewport = evt.map.getViewport();
        let foundSelectable = false;
        map?.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
          if (
            !layer &&
            viewport.style.cursor &&
            !/inherit/.test(viewport.style.cursor)
          ) {
            return true;
          }
          if (
            this.selectInteraction.getFeatures().getArray().includes(feature)
          ) {
            return true;
          }
          foundSelectable =
            this.options.selectInteractionOptions.filter(feature, layer) ||
            false;
          return foundSelectable;
        });
        targetElement.style.cursor = foundSelectable ? "pointer" : "inherit";
      }),
    ];

    if (features) {
      this.onSelect(features);
    }
  }

  createModifyInteraction(options = {}) {
    const interaction = new Modify(options);

    interaction.on("modifystart", (evt) => {
      this.editor.setEditFeature(evt.features.item(0));
      this.isModifying = true;
      this.snapControl?.activate();
    });

    interaction.on("modifyend", (evt) => {
      this.editor.setEditFeature();
      this.isModifying = false;
      this.snapControl?.deactivate();

      // Propagate the event so the feature can react when the user has finished modifying it.
      // See FeatureStyler for example.
      evt.features.forEach((feat) => {
        feat.dispatchEvent(evt);
      });
    });
    return interaction;
  }

  deactivate() {
    unByKey(this.olKeys);
    this.deleteInteraction?.setFeatures();
    this.selectInteraction?.getFeatures().clear();
    this.transformControl.deactivate();
    if (this.modifyInteraction) {
      this.map?.removeInteraction(this.modifyInteraction);
    }
    this.map?.removeInteraction(this.deleteInteraction);
    this.map?.removeInteraction(this.selectInteraction);
    super.deactivate();
  }

  onSelect(features = []) {
    const featuresToTransform = features;
    const featuresToModify = features.filter((f) =>
      /LineString|Polygon|LinearRing/.test(f?.getGeometry()?.getType()),
    );
    this.transformControl.deactivate();
    if (this.modifyInteraction) {
      this.map?.removeInteraction(this.modifyInteraction);
    }

    if (featuresToModify.length > 0) {
      this.modifyInteraction = this.createModifyInteraction({
        features: new Collection(featuresToModify),
        ...this.options.modifyInteractionOptions,
      });
      this.map?.addInteraction(this.modifyInteraction);
    }

    if (featuresToTransform) {
      this.deleteInteraction.setFeatures(new Collection(featuresToTransform));
      this.transformControl.activate(featuresToTransform);
    }
  }
}

export default ModifyControl;
