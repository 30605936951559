import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import createDebounce from "redux-debounced";
import { thunk } from "redux-thunk";

import app from "./app/reducers";
import map from "./map/reducers";
import oidc from "./oidc/reducers";

const store = createStore(
  combineReducers({
    app,
    map,
    oidc,
  }),
  compose(applyMiddleware(createDebounce(), thunk)),
);

export default store;
