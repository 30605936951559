import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles((theme) => {
  return {
    box: {
      "&:hover": {
        backgroundColor: theme.colors.grayAltLight,
      },
      border: `1px solid ${theme.colors.grayAltLight}`,
      borderRadius: 2,
      height: 18,
      margin: 5,
      position: "relative",
      width: 18,
      zIndex: 2,
    },
    tick: {
      "&::after": {
        backgroundColor: theme.palette.primary.main,
        clipPath:
          "polygon(12% 52%, 0 65%, 35% 100%, 100% 30%, 88% 18%, 35% 76%)",
        content: '""',
        height: 14,
        left: 2,
        position: "absolute",
        top: 2,
        width: 14,
      },
    },
  };
});

function CheckboxIcon({ checked = false }) {
  const classes = useStyles();
  return (
    <div className={`${classes.box}${checked ? ` ${classes.tick}` : ""}`} />
  );
}

CheckboxIcon.propTypes = {
  checked: PropTypes.bool,
};

export default CheckboxIcon;
