// Always make sure red color is the first in the array (is selected for defaullt color for lines and polygons)
const defaultColors = [
  {
    border: "black",
    fill: [228, 5, 33, 1],
    hex: "#e10025",
    name: "redRheinbahn",
  },
  {
    border: "black",
    fill: [155, 25, 38, 1],
    hex: "#9b1926",
    name: "darkRedRheinbahn",
  },
  {
    border: "white",
    fill: [155, 77, 117, 1],
    hex: "#9b4d75",
    name: "purpleSEV",
  },
  { border: "white", fill: [0, 86, 164, 1], hex: "#0056a4", name: "blue" },
  {
    border: "black",
    fill: [206, 55, 131, 1],
    hex: "#ce3783",
    name: "pinkPhase1",
  },
  {
    border: "black",
    fill: [127, 22, 83, 1],
    hex: "#7f1653",
    name: "pinkPhase2",
  },
];

export default {
  colors: defaultColors,
  iconGeneratorColors: defaultColors,
  lineColors: defaultColors,
  polygonFillColors: defaultColors,
  polygonLineColors: defaultColors,
  textBgColors: [
    { fill: [255, 255, 255, 0.01], name: "none" },
    ...defaultColors,
  ],
};
