/**
 * This function defines, from the layerService and the paermalink parameter
 * which is/must be the current base layer to display on the map.
 * It avoids duplication of code.
 */
function getCurrentBaseLayer(baseLayers, baseLayerParam) {
  if (baseLayerParam) {
    const baseLayer = baseLayers.find((l) => l.key === baseLayerParam);
    if (baseLayer) {
      // If the base layer in the url exists, make it visible.
      baseLayer.visible = true;
    }
  }

  if (!baseLayers.filter((layer) => layer.visible).length) {
    // If no base layer are visible, set the first one as visible.
    // eslint-disable-next-line no-param-reassign
    baseLayers[0].visible = true;
  }
  const currentBaseLayer = baseLayers.find((layer) => layer.visible);
  return currentBaseLayer;
}

export default getCurrentBaseLayer;
