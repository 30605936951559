const defaultColors = [
  { border: "black", fill: [255, 0, 0, 1], hex: "#ff0000", name: "red" },
  {
    border: "black",
    fill: [255, 230, 51, 1],
    hex: "#ffe633",
    name: "yellow_sti",
  },
  { border: "white", fill: [45, 50, 125, 1], hex: "#2d327d", name: "blue" },
  {
    border: "black",
    fill: [255, 102, 0, 1],
    hex: "#ff6600",
    name: "orange",
  },
  {
    border: "black",
    fill: [242, 166, 0, 1],
    hex: "#f2a600",
    name: "yellow_gwb",
  },
  { border: "white", fill: [0, 0, 0, 1], hex: "#000000", name: "black" },
];

const textColors = [
  {
    border: "white",
    fill: [57, 71, 86, 1],
    hex: "#394756",
    name: "gray_sti",
  },
  { border: "white", fill: [0, 0, 0, 1], hex: "#000000", name: "black" },
  {
    border: "black",
    fill: [255, 255, 255, 1],
    hex: "#ffffff",
    name: "white",
  },
  {
    border: "black",
    fill: [0, 70, 232, 1],
    hex: "#0046E8",
    name: "blue_gwb",
  },
  {
    border: "black",
    fill: [255, 102, 0, 1],
    hex: "#ff6600",
    name: "orange",
  },
  { border: "black", fill: [255, 0, 0, 1], hex: "#ff0000", name: "red" },
];

export default {
  colors: textColors,
  iconGeneratorColors: defaultColors,
  lineColors: defaultColors,
  polygonFillColors: defaultColors,
  polygonLineColors: defaultColors,
  textBgColors: [
    { fill: [255, 255, 255, 0.01], name: "none" },
    ...defaultColors,
  ],
};
