const getCopyrightAsString = (layers) => {
  const copyrights = [];
  layers
    .filter((layer) => layer.visible && layer.copyrights)
    .forEach((layer) => {
      layer.copyrights.forEach((copyright) => {
        // If copyright is HTML
        if (/^</.test(copyright)) {
          const parsed = new DOMParser().parseFromString(
            copyright,
            "text/html",
          );
          [...parsed.getElementsByTagName("a")].forEach((tag) =>
            copyrights.push(tag.text),
          );
        } else {
          // If copyright is only text
          copyrights.push(copyright);
        }
      });
    });
  const unique = Array.from(new Set(copyrights));
  return unique.join(" | ");
};

export default getCopyrightAsString;
