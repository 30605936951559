const getSizeFromTextAndFont = (text, font, textStyle) => {
  const scale = textStyle?.getScale();
  const textAlign = textStyle?.getTextAlign();
  const textBaseline = textStyle?.getTextBaseline();
  const strokeWidth = textStyle?.getStroke()?.getWidth() || 0;

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = font;
  context.textAlign = textAlign;
  context.textBaseline = textBaseline;
  const metrics = context.measureText(text);
  return [
    (metrics.width + strokeWidth * 2) * scale,
    (metrics.fontBoundingBoxAscent +
      metrics.fontBoundingBoxDescent +
      strokeWidth * 2) *
      scale,
  ];
};
/**
 * Return an approimative size of the canvas drawn by this text style.
 */
const getSizeFromTextStyle = (textStyle) => {
  const text = textStyle?.getText();
  const font = textStyle?.getFont();
  const scale = textStyle?.getScale();
  const bg = textStyle?.getBackgroundFill();
  const textArray = Array.isArray(text) ? text : [text, font];
  const sizes = [];

  if (Array.isArray(textArray)) {
    let lineSize = [0, 0];
    for (let i = 0; i < textArray.length; i += 2) {
      const txt = textArray[i];
      const ft = textArray[i + 1] || font;
      if (txt === "\n") {
        sizes.push(lineSize);
        lineSize = [0, 0];
      } else if (txt?.length && txt !== "\n") {
        const textSize = getSizeFromTextAndFont(txt, ft, textStyle);
        lineSize = [
          lineSize[0] + textSize[0],
          Math.max(lineSize[1], textSize[1]),
        ];
        if (i + 1 === textArray.length - 1) {
          sizes.push(lineSize);
        }
      }
    }
  }
  let width = 0;
  let height = 0;
  sizes.forEach(([w, h]) => {
    if (w > width) {
      width = w;
    }
    height += h;
  });
  const size = [width, height];

  if (bg) {
    size[0] += 12 * scale;
    size[1] += 8 * scale;
  }

  return size;
};

export default getSizeFromTextStyle;
