import colors from "./colors";
import iconCategories from "./icons.json";

const config = {
  domains: ["ch"],
  font: "16px arial",
  iconCategories,
  iconGeneratorActive: true,
  id: "winterthur",
  name: "Winterthur",
  slug: "win",
  ssoTags: ["tp.ch.win"],
  ...colors,
};
export default config;
