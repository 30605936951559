import MapsetLogo from "../images/mapset_logo.svg";
import NorthArrow from "../images/northArrow.svg";
import {
  EXTRA_DATA_COPYRIGHT_DEFAULT_FONTSIZE,
  EXTRA_DATA_COPYRIGHT_MIN_FONTSIZE,
  EXTRA_DATA_LOGO_MIN_HEIGHT,
  EXTRA_DATA_LOGO_MIN_WIDTH,
  EXTRA_DATA_NORTH_ARROW_MIN_SIZE,
  EXTRA_DATA_SIZE,
} from "./constants";
import { getDegreesFromRadians } from "./styleUtils";

export const getLogoExtraData = (scaleFactor, exportQuality) => {
  const aspectRatio = EXTRA_DATA_LOGO_MIN_HEIGHT / EXTRA_DATA_LOGO_MIN_WIDTH;
  let scaledHeight = EXTRA_DATA_SIZE * aspectRatio * scaleFactor;
  let scaledWidth = (EXTRA_DATA_SIZE + 1) * scaleFactor;
  if (scaledHeight < EXTRA_DATA_LOGO_MIN_HEIGHT) {
    scaledHeight = EXTRA_DATA_LOGO_MIN_HEIGHT;
  }
  if (scaledWidth < EXTRA_DATA_LOGO_MIN_WIDTH) {
    scaledWidth = EXTRA_DATA_LOGO_MIN_WIDTH;
  }
  return {
    height: scaledHeight * exportQuality,
    src: MapsetLogo,
    width: scaledWidth * exportQuality,
  };
};

export const getNorthArrowExtraData = (
  scaleFactor,
  exportQuality,
  rotation,
) => {
  // NorthArrow scales with export frame, minimum size is 30x30
  let northArrowScaledSize = EXTRA_DATA_SIZE * scaleFactor;
  if (northArrowScaledSize < EXTRA_DATA_NORTH_ARROW_MIN_SIZE) {
    northArrowScaledSize = EXTRA_DATA_NORTH_ARROW_MIN_SIZE;
  }
  const northArrowSize = northArrowScaledSize * exportQuality;
  return {
    height: northArrowSize,
    rotation: () => getDegreesFromRadians(rotation),
    src: NorthArrow,
    width: northArrowSize,
  };
};

export const getCopyrightExtraData = (
  scaleFactor,
  text,
  exportQuality,
  maxWidth,
) => {
  let scaledFontSize = EXTRA_DATA_COPYRIGHT_DEFAULT_FONTSIZE * scaleFactor;
  if (scaledFontSize < EXTRA_DATA_COPYRIGHT_MIN_FONTSIZE) {
    scaledFontSize = EXTRA_DATA_COPYRIGHT_MIN_FONTSIZE;
  }
  return {
    background: true,
    font: `${Math.ceil(scaledFontSize) * exportQuality}px Arial`,
    maxWidth,
    paddingBottom: 5 * exportQuality,
    text,
  };
};

export const getQrCodeExtraData = (
  url,
  exportQrCodeWidth = EXTRA_DATA_SIZE,
  exportQuality,
) => {
  return {
    height: exportQrCodeWidth * exportQuality,
    src: url,
    width: exportQrCodeWidth * exportQuality,
  };
};
