import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { fetchDrawInfo } from "../../model/app/actions";

const useFetchDrawInfo = (id, uuid) => {
  const user = useSelector((state) => state.oidc.user);
  const apiUrl = useSelector((state) => state.app.apiUrl);
  const [drawInfo, setDrawInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    fetchDrawInfo(apiUrl, user, id, uuid).then((data) => {
      setLoading(false);
      setDrawInfo(Array.isArray(data) ? data.results[0] : data);
    });
  }, [apiUrl, user, id, uuid]);
  return { drawInfo, loading };
};

export default useFetchDrawInfo;
