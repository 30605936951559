import { Layer } from "mobility-toolbox-js/ol";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { getUid } from "ol/util";

import createStyles from "./createStyles";

const cloneLayer = (editLayer, map) => {
  const saveLayer = new Layer({
    name: "saveLayer",
    olLayer: new VectorLayer({
      source: new VectorSource({
        // [MAPSETW-446] To make sure we don't use the rbush tree.
        useSpatialIndex: false,
      }),
    }),
    zIndex: editLayer.olLayer.getZIndex(),
  });
  const clonedFeatureArray = [...editLayer.olLayer.getSource().getFeatures()]
    .sort((a, b) => {
      // [MAPSETW-446] The order of feature must be kept.
      // We could use the useSpatialIndex = false property on the layer
      // but we prefer to sort feature by ol uid because ol uid is an integer
      // increased on each creation of a feature.
      // So we will keep the order of creation made by the the KML parser.
      // Ideally we should not care of this and use unique zIndex values for every feature.
      if (getUid(a) <= getUid(b)) {
        return -1;
      }
      return 1;
    })
    .map((feature) => {
      const featureClone = feature.clone();
      const styleFunction = featureClone.getStyleFunction();
      if (styleFunction) {
        let styles = featureClone.getStyleFunction()(
          feature,
          map.getView().getResolution(),
        );
        styles = Array.isArray(styles) ? styles : [styles];
        featureClone.setStyle((feat, res) =>
          createStyles(feat, res, styles, map, true),
        );
      }
      return featureClone;
    });

  saveLayer.olLayer.getSource().addFeatures(clonedFeatureArray);
  return saveLayer;
};

export default cloneLayer;
